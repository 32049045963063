import { Toggle, Modal, Button, Radio } from 'rsuite';
import axios from "axios";
import { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router";
import {Form, InputPicker, Input, Message, Checkbox} from "rsuite";
import { useMediaQuery } from 'react-responsive';
import { Notification, toaster  } from 'rsuite';

export default function Transfer(props) {
    const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
    const dispatch = useDispatch();
    const history = useHistory();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [formMessage, setFormMessage] = useState("");
    const [formMessageType, setFormMessageType] = useState("");
    const [transferUsers, setTransferUsers] = useState([]);
    const [transferLoading, setTransferLoading] = useState(false);

    const [transferDir, setTransferDir] = useState("right");

    const types = [
        {
            label : "Real Balance",
            value : 0
        },
        {
            label : "Bonus Balance",
            value : 1
        },
        {
            label : "Sports Bonus Balance",
            value : 2
        }
    ]

    const [transferForm, setTransferForm] = useState({
        from : "self",
        to : modals.transferUser != "" && modals.transferUser != null ? modals.transferUser : "",
        amount : 0,
        note : "",
        transferType : 0
    })
    const [errors, setErrors] = useState({
        from : false,
        to : false,
        amount : false
    })

    const [checked, setChecked] = useState(false);

    const toggleTransferModal = () => {
        dispatch({
            type : "TOGGLE_TRANSFER_MODAL",
            data : {
                toggle : false
            }
        })
        if(props.isPage) {
            history.push("/")
        }
    }

    const changeTransferDir = () => {
        if(transferDir == "right") {
            setTransferDir("left");
        } else {
            setTransferDir("right");
        }
        setTransferForm({
            ...transferForm,
            from : transferForm.to,
            to : transferForm.from
        })
    }

    const ChangePlatform = function(platform) {
        axios.post(window.__APP__.api+"/platform/change", {
            platform : platform
        }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                var platform = response.data.platform;

                localStorage.setItem("platform", platform.platform_id);

                dispatch({
                    type : "SET_PLATFORM",
                    data : {
                      platform : {
                        id : platform.platform_id,
                        name : platform.platform_name,
                        type : platform.platform_type,
                        credits_type : platform.platform_credits_type,
                        credits : platform.platform_credits,
                        is_limited : platform.is_limited,
                        is_mc : platform.is_mc,
                        agent_enabled : platform.agent_enabled,
                        external_agent : platform.external_agent,
                        version : platform.version,
                        mbank_enabled : platform.mbank_enabled,
                        kiosk_agent : platform.kiosk_agent
                      }
                    }
                })
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    const MakeTransfer = () => {
        if(transferForm.from == "" || transferForm.from == null || transferForm.to == "" || transferForm.to == null || transferForm.amount == "" || transferForm.amount <= 0) {
            var errorObj = errors;
            if(transferForm.from == "" || transferForm.from == null) {
                errorObj.from = true;
            } else {
                errorObj.from = false
            }
            if(transferForm.to == "" || transferForm.to == null) {
                errorObj.to = true;
            } else {
                errorObj.to = false
            }
            if(transferForm.amount == "" || transferForm.amount <= 0) {
                errorObj.amount = true;
            } else {
                errorObj.amount = false;
            }
            var obj = Object.assign({}, errorObj);
            setErrors(obj)
        } else if(transferForm.from != "" && transferForm.from == transferForm.to) {
            setErrors({
                ...errors,
                from : true,
                to : true,
                amount : false
            })
        } else {
            setErrors({
                from : false,
                to : false,
                amount : false
            })

            setTransferLoading(true);

            axios.post(window.__APP__.api+"/transactions/online/transfer/make?platform="+platforms.platform.id, transferForm, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            }).then(response => {
                setTransferLoading(false);

                if(response.data.error == null) {
                    setFormMessageType("");
                    setFormMessage("");

                    ChangePlatform(platforms.platform.id);

                    toaster.push(<Notification type="info" header="Notification" closable>Funds transfered successfully</Notification>, {placement: "topEnd"});

                    toggleTransferModal();
                } else {
                    switch(response.data.error) {
                        case "Platform Not Found":
                        case "Unexpected Error":
                          setFormMessage("Unexpected Error");
                          setFormMessageType("error");
                        break;
                        case "Operation Not Permitted":
                            setFormMessage("Operation denied");
                            setFormMessageType("error");
                        break;
                        case "Not Enough Funds":
                            setFormMessage("Not Enough Funds");
                            setFormMessageType("error");
                        break;
                        case "Unauthorized":
                          dispatch({
                              type : "SET_LOGGED",
                              data : {
                                  logged : false
                              }
                          })
                          localStorage.removeItem("jwt");
                          history.push("/");
                        break;
                        default:
                            setFormMessage(response.data.error);
                            setFormMessageType("error");
                        break;
                    }
                }
            })
        }
    }

    const GetUsers = () => {
        var type = modals.transferType;

        axios.get(window.__APP__.api+"/transactions/online/users/list?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setTransferUsers(response.data.users)
            }   
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetUsers();
        }   
    }, [platforms]);

    const PerformTransfer = () => {
        MakeTransfer();
    }

    return (
        <Modal full={isMobile} className="modal-index" onClose={toggleTransferModal} open={true}>
            <Modal.Header>
                <Modal.Title>Make Transfer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="form-box transfer-form">
                    {
                        formMessage != "" && (
                        <Message style={{ marginBottom : "15px" }} showIcon type={formMessageType}>
                            {formMessage}
                        </Message>
                        )
                    }
                    <div className="group-box transfer-box-inputs" style={{display : "flex"}}>
                        <Form.Group className="group-item" controlId="from">
                            <Form.ControlLabel>Transfer From</Form.ControlLabel>
                            <InputPicker value={transferForm.from} onChange={value => setTransferForm({...transferForm, from : value})} className={errors.from ? "errored" : ""} groupBy="role" data={transferUsers} placeholder="From" />
                        </Form.Group>
                        <div className="transfer-section">
                            <i onClick={changeTransferDir} class={"fas fa-arrow-"+transferDir}></i>
                        </div>
                        <Form.Group className="group-item" controlId="to">
                            <Form.ControlLabel>Transfer To</Form.ControlLabel>
                            <InputPicker value={transferForm.to} onChange={value => setTransferForm({...transferForm, to : value})} className={errors.to ? "errored" : ""} groupBy="role" data={transferUsers} placeholder="To" />
                        </Form.Group>
                    </div>
                    <div className="group-box" style={{display : "flex", width : "100%", marginBottom : "15px"}}>
                        <Form.Group className="group-item" controlId="to">
                            <Form.ControlLabel>Transfer Type</Form.ControlLabel>
                            <InputPicker style={{width : "100%"}} value={transferForm.transferType} onChange={value => setTransferForm({...transferForm, transferType : value})} className={errors.type ? "errored" : ""} groupBy="role" data={types} placeholder="Transfer Type" />
                        </Form.Group>
                    </div>
                    <div className="group-box" style={{display : "flex"}}>
                        <Form.Group controlId="amount" className="group-item">
                            <Form.ControlLabel>Transfer Amount</Form.ControlLabel>
                            <Input onChange={value => setTransferForm({...transferForm, amount : parseFloat(value)})} className={errors.amount ? "errored" : ""} type="number" placeholder="Transfer Amount" />
                        </Form.Group>
                    </div>
                    {
                        platforms.platform.type != 3 ? (
                            <div className="group-box" style={{display : "flex", marginTop : "10px"}}>
                                <Form.Group controlId="paid" className="group-item">
                                    <Checkbox checked={checked} onChange={() => {checked ? setChecked(false) : setChecked(true)}}>Transaction Paid</Checkbox>
                                </Form.Group>
                            </div>
                        ) : (
                            <></>
                        )
                    }
                    <div className="group-box" style={{display : "flex", marginTop: "20px"}}>
                        <Form.Group controlId="note" className="group-item">
                            <Form.ControlLabel>Transfer Note</Form.ControlLabel>
                            <textarea onChange={event => setTransferForm({...transferForm, note : event.target.value})}></textarea>
                        </Form.Group>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleTransferModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={PerformTransfer} loading={transferLoading} appearance="primary">
                    Make Transfer
                </Button>
            </Modal.Footer>
        </Modal>
    )
}