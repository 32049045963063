import {Table, IconButton, InputPicker} from "rsuite";
import Pagination from 'rsuite/Pagination';

import {Link} from "react-router-dom";
import { Tooltip, Whisper } from 'rsuite';
import { Toggle, Modal, Button, Radio, CheckboxGroup, Checkbox  } from 'rsuite';

import { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router";

import Transfer from "../../Transfer/Transfer";

import ReactExport from "react-data-export";
import { CSVLink, CSVDownload } from "react-csv";

import axios from "axios";
import moment from "moment";

function Download(props) {

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


    return (
        <>
            {
                props.dataType == 0 ? (
                    <ExcelFile hideElement={true} filename="Players Data" fileExtension={props.dataType == 0 ? "xlsx" : "csv"}>
                        <ExcelSheet data={props.data} name="Players">
                            {
                                props.scolumns.filter(d => {
                                    return d.active == true
                                }).map(column => {
                                    return (
                                        <ExcelColumn label={column.name} value={column.key} />
                                    )
                                })
                            }
                        </ExcelSheet>
                    </ExcelFile>
                ) : (
                    <>
                        <CSVDownload data={props.csvdata} target="_blank" />;
                    </>
                )
            }
        </>
    );
}
const selectKeys = (arr, keys) => {
    return arr.map(item => 
      keys.reduce((acc, key) => {
        if (item.hasOwnProperty(key)) {
          acc[key] = item[key];
        }
        return acc;
      }, {})
    );
};
const ExportModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);
    
    const [dataPage, setDataPage] = useState(0);
    const [dataType, setDataType] = useState(0);
    const [loading, setLoading] = useState(false);

    const [downloadable, setDownloadable] = useState(false);
    const [data, setData] = useState([]);
    const [csvdata, setCsvData] = useState([]);
    const [scolumns, setSColumns] = useState([]);

    const ExportData = async () => {
        setLoading(true);

        const response = await axios.get(window.__APP__.api+"/players/list?platform="+platforms.platform.id+"&page="+props.page+"&limit="+props.limit+"&player="+props.filters.player+"&cin="+props.filters.cin+"&country="+props.filters.country+"&email="+props.filters.email+"&phone="+props.filters.phone+"&id="+props.filters.pid+"&mtsid="+props.filters.mtsid+"&firstname="+props.filters.firstname+"&lastname="+props.filters.lastname+"&birthday="+props.filters.birthday+"&ip="+props.filters.ip+"&currencies="+props.filters.currency.toString()+"&parents="+props.filters.parent.toString()+"&ShowAllData="+dataPage, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        setLoading(false);

        if(response.data.error == null) {
            setData(response.data.shops);

            var columns = [];

            for(var k=0; k<props.columns.length; k++) {
                var column = props.columns[k];

                if(column.active == true) {
                    columns.push(column.key)
                }
            }

            setSColumns(columns);
            setCsvData(selectKeys(response.data.shops, columns))

            setDownloadable(true);

            props.closeModal();
        }
    }   

    return (
        <Modal onClose={props.closeModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Export Players Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="export-data-body">
                    <div className="export-data-row">
                        <label>Export Data Page</label>
                        <InputPicker onChange={v => setDataPage(v)} value={dataPage} placeholder="Export Data Page" data={[
                            {
                                label : "Current Page Data",
                                value : 0
                            },
                            {
                                label : "All Data",
                                value : 1
                            }
                        ]}></InputPicker>
                    </div>

                    <div className="export-data-row">
                        <label>Export Data Type</label>
                        <InputPicker onChange={v => setDataType(v)} value={dataType} placeholder="Export Data Type" data={[
                            {
                                label : "XLS",
                                value : 0
                            },
                            {
                                label : "CSV",
                                value : 1
                            }
                        ]}></InputPicker>
                    </div>
                </div>
                {
                    downloadable ? (
                        <Download scolumns={props.columns} dataType={dataType} csvdata={csvdata} data={data} />
                    ) : (
                        <></>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.closeModal} appearance="subtle">
                    Close
                </Button>
                <Button loading={loading} onClick={ExportData} color="primary" appearance="primary">
                    Export
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
const ColumnsModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const userx = useSelector(state => state.user);

    var [mcolumns, setMColumns] = useState(props.dcolumns);

    const UpdateColumnStatus = (key) => {
        var m = Object.assign([], mcolumns);

        var checkColumn = m.findIndex(d => {
            return d.key == key
        })

        if(checkColumn >= 0) {
            if(m[checkColumn].active) {
                m[checkColumn].active = false
            } else {
                m[checkColumn].active = true
            }
        }

        setMColumns(m);
    }

    const Save = () => {
        props.UpdateColumns(mcolumns);
        props.closeModal()
    }

    return (
        <Modal onClose={props.closeModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Select Table Columns</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="checkbox-columnss">
                    {
                        props.dcolumns.map(column => {
                            return (
                                <Checkbox onChange={v => UpdateColumnStatus(column.key)} checked={column.active ? true : false}>{column.name}</Checkbox>
                            )
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.closeModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={Save} color="primary" appearance="primary">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);
    const userx = useSelector(state => state.user);

    const togglePlayerDeleteModal = (id) => {
        dispatch({
            type : "SET_PLAYER_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_PLAYER_DELETE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const togglePlayerToggleModal = (id) => {
        dispatch({
            type : "SET_PLAYER_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "SET_PLAYER_STATUS",
            data : {
                status : rowData['status']
            }
        })
        dispatch({
            type : "TOGGLE_PLAYER_TOGGLE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const toggleTransferModal = (id) => {
        dispatch({
            type : "SET_TRANSFER_USER",
            data : {
                id : "player-"+id
            }
        })
        dispatch({
            type : "SET_TRANSFER_TYPE",
            data : {
                type : "partner"
            }
        })
        dispatch({
            type : "TOGGLE_TRANSFER_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const GetAuthUrl = async (id) => {
        var response = await axios.get(window.__APP__.api+"/players/autoauthenticate/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.token != undefined) {
            window.open(response.data.token, "_blank");
        }
    }

    return (
      <Table.Cell {...props} className="link-group">
        <Link to={"/online/player/"+rowData['id']} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Preview</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
            </Whisper>
        </Link>
        {
            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("players_edit"))) >= 0 ? (
                <Link to={"/online/player/edit/"+rowData['id']} style={{marginRight : "10px"}}>
                    <Whisper
                        placement={"top"}
                        speaker={
                            <Tooltip>Edit</Tooltip>
                        }
                    >
                        <IconButton className="table-button no-icon"><i className="fas fa-edit"></i></IconButton>
                    </Whisper>
                </Link>
            ) : (
                <></>
            )
        }
        {/* <a onClick={() => GetAuthUrl(rowData['id'])} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Login</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-key"></i></IconButton>
            </Whisper>
        </a> */}
        {
            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("players_delete"))) >= 0 ? (
                <a onClick={() => togglePlayerDeleteModal(rowData['id'])} style={{marginRight : "10px"}}>
                    <Whisper
                        placement={"top"}
                        speaker={
                            <Tooltip>Delete</Tooltip>
                        }
                    >
                        <IconButton className="table-button no-icon"><i className="fas fa-trash-alt"></i></IconButton>
                    </Whisper>
                </a>
            ) : (
                <></>
            )
        }
        {
            (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("players_toggle"))) >= 0 ? (
                <Toggle color="red" appearance="primary" checked={!rowData['status']} onChange={() => togglePlayerToggleModal(rowData['id'])} className="table_toggle" size="sm"></Toggle>
            ) : (
                <></>
            )
        }
      </Table.Cell>
    );
};

const DeleteModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_PLAYER_DELETE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const DeletePartner = () => {
        axios.post(window.__APP__.api+"/players/delete/"+modals.playerId+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Delete Player #{modals.playerId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to delete this player ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={DeletePartner} color="red" appearance="primary">
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ToggleModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [toggleType, setToggleType] = useState(1);

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_PLAYER_TOGGLE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const TogglePartner = () => {
        axios.post(window.__APP__.api+"/players/toggle/"+modals.playerId+"?platform="+platforms.platform.id, {type : toggleType}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    const setToggleEvent = (toggle, value) => {
        if(toggle == "1") {
            setToggleType(1)
        } else {
            setToggleType(0);
        }
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>{modals.playerStatus == 1 ? "Disable" : "Enable"} Player #{modals.playerId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Toggle Type</h4>
                <br />
                <Radio onChange={setToggleEvent} value="1" checked={toggleType == 1 ? true : false}>Only User</Radio>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={TogglePartner} appearance="primary">
                    {modals.playerStatus == 1 ? "Disable" : "Enable"}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


export default function PlayersTable(props) {
    const dispatch = useDispatch();
    const history = useHistory();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);

    const [pages, setPages] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [activeLimit, setActiveLimit] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    const {mtsid, pid, player, cin, country, phone, email, firstname, lastname, birthday, ip, currency, parent, btag} = props;

    const [dcolumns, setDColumns] = useState([
        {
            key : "id",
            name : "Id",
            active : true
        },
        {
            key : "mtsId",
            name : "mtsid",
            active : true
        },
        {
            key : "name",
            name : "Player Name",
            active : true
        },
        {
            key : "username",
            name : "Username",
            active : true
        },
        {
            key : "status",
            name : "Status",
            active : true
        },
        {
            key : "country",
            name : "Country",
            active : true
        },
        {
            key : "state",
            name : "State",
            active : true
        },
        {
            key : "city",
            name : "City",
            active : true
        },
        {
            key : "zip",
            name : "ZIP",
            active : true
        },
        {
            key : "phone",
            name : "Phone",
            active : true
        },
        {
            key : "email",
            name : "Email",
            active : true
        },
        {
            key : "birthday",
            name : "Birthday",
            active : true
        },
        {
            key : "balance",
            name : "Balance",
            active : true
        },
        {
            key : "date",
            name : "Date Created",
            active : true
        },
        {
            key : "source",
            name : "Source",
            active : true
        },
        {
            key : "parent",
            name : "Parent",
            active : true
        },
        {
            key : "currency",
            name : "Currency",
            active : true
        }
    ]);

    const GetColumnStatus = (key) => {
        var d = dcolumns.findIndex(d => {
            return d.key == key
        })

        if(d >= 0) {
            if(dcolumns[d].active) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    const GetPlayers = () => {
        setLoading(true);
        setTableData([]);
        // setActivePage(1);
        
        axios.get(window.__APP__.api+"/players/list?platform="+platforms.platform.id+"&page="+activePage+"&limit="+activeLimit+"&player="+player+"&cin="+cin+"&country="+country+"&email="+email+"&phone="+phone+"&id="+pid+"&mtsid="+mtsid+"&firstname="+firstname+"&lastname="+lastname+"&birthday="+birthday+"&ip="+ip+"&currencies="+currency.toString()+"&parents="+parent.toString()+"&btag="+btag, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setTableData(response.data.shops);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetPlayers();
    }, [platforms.platform, ip, mtsid, pid, phone, email, activePage, activeLimit, modals.playerDeleteModal, modals.playerToggleModal, player, cin, country, firstname, lastname, birthday, currency, parent, btag]);

    
    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={tableData}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                loading={loading}
            >
                {
                    GetColumnStatus("id") ? (
                        <Table.Column width={70} align="center" fixed>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("mtsId") ? (
                        <Table.Column width={70} align="center" fixed>
                            <Table.HeaderCell>MTS Id</Table.HeaderCell>
                            <Table.Cell dataKey="mtsId" />
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("name") ? (
                        <Table.Column flexGrow={1} minWidth={120}>
                            <Table.HeaderCell>Player Name</Table.HeaderCell>
                            <Table.Cell dataKey="name" />
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("username") ? (
                        <Table.Column flexGrow={1} minWidth={120}>
                            <Table.HeaderCell>Username</Table.HeaderCell>
                            <Table.Cell>{rowData => <Link to={"/online/player/"+rowData.id}>{rowData.username}</Link>}</Table.Cell>
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("status") ? (
                        <Table.Column flexGrow={1} minWidth={120}>
                            <Table.HeaderCell>Account Status</Table.HeaderCell>
                            <Table.Cell>{data => data.status == 1 ? "Active" : "Disabled"}</Table.Cell>
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("source") ? (
                        <Table.Column flexGrow={1} minWidth={120}>
                            <Table.HeaderCell>Source</Table.HeaderCell>
                            <Table.Cell>{data => data.sourceName}</Table.Cell>
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    platforms.platform.agent_enabled == 1 ? (
                        <>
                            {
                                GetColumnStatus("parent") ? (
                                    <Table.Column flexGrow={1} minWidth={120}>
                                        <Table.HeaderCell>Parent</Table.HeaderCell>
                                        <Table.Cell>{ data => <>{ (data.source == 1 && data.parentId != null) ? <Link to={"/online/agent/"+data.parentId}>{data.parent}</Link> : <>-</> }</>}</Table.Cell>
                                    </Table.Column>
                                ) : (
                                    <></>
                                )
                            }
                        </>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("country") ? (
                        <Table.Column flexGrow={1} minWidth={120}>
                            <Table.HeaderCell>Country</Table.HeaderCell>
                            <Table.Cell dataKey="country" />
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("state") ? (
                        <Table.Column flexGrow={1} minWidth={120}>
                            <Table.HeaderCell>State</Table.HeaderCell>
                            <Table.Cell dataKey="state" />
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("city") ? (
                        <Table.Column flexGrow={1} minWidth={120}>
                            <Table.HeaderCell>City</Table.HeaderCell>
                            <Table.Cell dataKey="city" />
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("zip") ? (
                        <Table.Column flexGrow={1} minWidth={120}>
                            <Table.HeaderCell>ZIP</Table.HeaderCell>
                            <Table.Cell dataKey="zip" />
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("phone") ? (
                        <Table.Column flexGrow={1} minWidth={150}>
                            <Table.HeaderCell>Phone</Table.HeaderCell>
                            <Table.Cell dataKey="phone" />
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("email") ? (
                        <Table.Column flexGrow={1} minWidth={250}>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.Cell dataKey="email" />
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("birthday") ? (
                        <Table.Column flexGrow={1} minWidth={150}>
                            <Table.HeaderCell>Birthday</Table.HeaderCell>
                            <Table.Cell>{ data => <>{data.birthday != null ? moment(data.birthday).format("DD/MM/YYYY") : "-"}</> }</Table.Cell>
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("currency") ? (
                        <Table.Column flexGrow={1} minWidth={150}>
                            <Table.HeaderCell>Currency</Table.HeaderCell>
                            <Table.Cell>{ data => <>{data.currency != null ? data.currency : "-"}</> }</Table.Cell>
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("balance") ? (
                        <Table.Column flexGrow={1} minWidth={120}>
                            <Table.HeaderCell>Balance</Table.HeaderCell>
                            <Table.Cell dataKey="balance" />
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                {
                    GetColumnStatus("date") ? (
                        <Table.Column flexGrow={1} minWidth={120}>
                            <Table.HeaderCell>Date Created</Table.HeaderCell>
                            <Table.Cell dataKey="date" />
                        </Table.Column>
                    ) : (
                        <></>
                    )
                }
                
                <Table.Column flexGrow={1} minWidth={170} align="center" fixed={"right"}>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                    <ActionCell />
                </Table.Column>
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={activeLimit}
                    limitOptions={[25,50,100]}
                    maxButtons={5}
                    activePage={activePage}
                    onChangePage={setActivePage}
                    onChangeLimit={setActiveLimit}
                />
            </div>

            {
                modals.playerDeleteModal && (
                    <DeleteModal show={true} />
                )
            }
            {
                modals.playerToggleModal && (
                    <ToggleModal show={true} />
                )
            }
            {
                modals.transferModal && (
                    <Transfer />
                )
            }

            {
                props.showExportModal ? (
                    <ExportModal columns={dcolumns} filters={{mtsid, pid, player, cin, country, phone, email, firstname, lastname, birthday, ip, currency, parent}} limit={activeLimit} page={activePage} closeModal={props.closeExportModal} show={props.showExportModal}></ExportModal>
                ) : (
                    <></>
                )
            }

            {
                props.showColumnsModal ? (
                    <ColumnsModal UpdateColumns={setDColumns} dcolumns={dcolumns} showColumnsModal={true} show={props.showColumnsModal} closeModal={props.closeColumnsModal} />
                ) : (
                    <></>
                )
            }
        </>
    )
}