import { useEffect, useState, useMemo } from "react";
import {useHistory, useParams, useLocation} from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import "./Printable.css";

function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
}

export default function PrintReport() {

    let query = useQuery();

    const history = useHistory();
    const dispatch = useDispatch();

    const {id} = useParams();

    const filters = useSelector(state => state.filters);
    const platforms = useSelector(state => state.platforms);

    const [printable, setPrintableData] = useState([]);
    const [summary, setSummary] = useState({
        totalDeposit : 0,
        totalWithdraw : 0
    });
    const [shopBonus, setShopBonus] = useState(0);
    const [shopInfo, setShopInfo] = useState({});

    const [percentage, SetPercentage] = useState(0);

    const [fullReport, setFullReport] = useState({});

    const GetFullReport = () => {
        if(query.get("dateFrom") != "")
            var dateQuery = "&dateFrom="+query.get("dateFrom")+"&dateTo="+query.get("dateTo");
        else
            var dateQuery = "&dateFrom=&dateTo=";

        axios.get(window.__APP__.api+"/shops/getFullReport/"+id+"?platform="+platforms.platform.id+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setFullReport(response.data.report);

                setTimeout(() => {
                    window.print()
                }, 2000)
            }
        });
    }

    const GetTransactions = () => {
        if(query.get("dateFrom") != "")
            var dateQuery = "&dateFrom="+query.get("dateFrom")+"&dateTo="+query.get("dateTo");
        else
            var dateQuery = "&dateFrom=&dateTo=";

        axios.get(window.__APP__.api+"/shops/reporttransactions/"+id+"?platform="+platforms.platform.id+"&page="+1+"&limit="+25+"&filterType="+1+dateQuery, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setPrintableData(response.data.printable);

                var totals = {
                    totalDeposit : 0,
                    totalWithdraw : 0
                }

                for(var i=0; i<response.data.printable.length; i++) {
                    var it = response.data.printable[i];

                    if(it.deposit != "-") {
                        totals.totalDeposit += parseFloat(it.deposit);
                    }
                    if(it.withdraw != "-") {
                        totals.totalWithdraw += parseFloat(it.withdraw);
                    }
                }

                var profit = totals.totalDeposit-totals.totalWithdraw;

                SetPercentage(response.data.percentage)
                
                var bonus = parseFloat((profit*parseFloat(response.data.percentage))/100).toFixed(2);

                setShopBonus(bonus)

                setSummary(totals);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    const GetShopInfo = () => {
        axios.get(window.__APP__.api+"/shops/info/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setShopInfo(response.data.operator);
                SetPercentage(response.data.operator.percentage);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } else if(response.data.error == "Operator Not Found") {
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id > -1) {
            GetTransactions();
            GetShopInfo();
            GetFullReport();
        }
    }, [platforms.platform]);

    return (
        <>
            <h1 className="treport">TRANSACTIONS REPORT</h1>
            <div className="infoReport">
                {
                    shopInfo.username != undefined && (
                        <>
                            <div className="infoRow">
                                <p>SHOP USERNAME:</p>
                                <p style={{fontWeight : "bold"}}>{shopInfo.username}</p>
                            </div>
                            <div className="infoRow">
                                <p>ADDRESS:</p>
                                <p style={{fontWeight : "bold"}}>{shopInfo.address}</p>
                            </div>
                            <div className="infoParentRow">
                                <div className="infoRow">
                                    <p>SHOP OWNER:</p>
                                    <p style={{fontWeight : "bold"}}>{shopInfo.name}</p>
                                </div>
                                <div className="infoRow">
                                    <p>ID N�:</p>
                                    <p style={{fontWeight : "bold"}}>{shopInfo.cin}</p>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
            <table className="printTable">
                <tr>
                    <td style={{width : "200px"}} rowspan="2"><b>DATE AND TIME</b></td>
                    <td colSpan={2}><b>TRANSACTION TYPE</b></td>
                </tr>
                <tr>
                    <td><b>DEPOSIT</b></td>
                    <td><b>WITHDRAW</b></td>
                </tr>
                {
                    printable.map(p => {
                        return (
                            <tr>
                                <td className="diffWeight">{p.date}</td>
                                <td>{p.deposit}</td>
                                <td>{p.withdraw}</td>
                            </tr>
                        )
                    })
                }
                <tr>
                    <td>
                        <b>TOTAL</b>
                    </td>
                    <td>
                        <b>{summary.totalDeposit}</b>
                    </td>
                    <td>
                        <b>{summary.totalWithdraw}</b>
                    </td>
                </tr>
                <tr>
                    <td className="hiddenReportCell"></td>
                    <td>
                        SHOP BONUS
                    </td>
                    <td>
                        {
                            shopBonus >= 0 ? (
                                <b>{shopBonus} TND</b>
                            ) : (
                                <b>0 TND</b>
                            )
                        }
                        
                    </td>
                </tr>
            </table>
            
            <div style={{width : "80%", marginLeft : "auto", marginRight : "auto", marginTop : "20px"}}>
                <p><b>NB: SHOP BONUS = (DEPOSIT - WITHDRAW)*{percentage}</b></p>
            </div>

            {
                (fullReport.jacks != undefined && fullReport.jacks.length > 0) && (
                    <>
                    <div style={{width : "80%", marginLeft : "auto", marginRight : "auto", marginTop : "20px"}}>
                        <h4>Jackpots</h4>
                    </div>
                    <table className="printTable">
                        <tr>
                            <td><b>DATE</b></td>
                            <td><b>AMOUNT</b></td>
                        </tr>
                        {
                            fullReport.jacks.map(p => {
                                return (
                                    <tr>
                                        {/* <td className="diffWeight"></td> */}
                                        <td><b>{p.date}</b></td>
                                        <td>{p.amount}</td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                    </>
                )
            }


            {
                (fullReport.jackpots != undefined && fullReport.jackpots.findIndex(i => i.jackpot == "Grand Jackpot") >= 0) && (
                    <>
                        <table className="printTable">
                            <tr>
                                <td style={{width : "200px"}} rowspan="2"><b>GRAND JACKPOTS</b></td>
                            </tr>
                            <tr>
                                <td><b>TOTAL</b></td>
                                <td><b>BONUS</b></td>
                            </tr>
                            {
                                fullReport.jackpots.map(p => {
                                    return (
                                        <>
                                            {
                                                p.jackpot == "Grand Jackpot" && (
                                                    <tr>
                                                        <td className="diffWeight"></td>
                                                        <td>{p.payout}</td>
                                                        <td>{parseFloat((p.payout*percentage)/100).toFixed(2)}</td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                    )
                                })
                            }
                        </table>
                    </>
                )
            }

            <div style={{width : "80%", marginLeft : "auto", marginRight : "auto", marginTop : "100px", textAlign : "right", marginBottom : "100px"}}>
                <p><b>CLIENT'S SIGNATURE</b></p>
            </div>
        </>
    )
}