import { Table, IconButton } from "rsuite";
import Pagination from 'rsuite/Pagination';

import { Link } from "react-router-dom";
import { Tooltip, Whisper, Badge } from 'rsuite';
import { Toggle, Modal, Button } from 'rsuite';

import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { Radio, RadioGroup } from 'rsuite';
import moment from "moment/moment";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    const toggleApproveModal = (id) => {
        dispatch({
            type: "SET_WITHDRAWAL_ID",
            data: {
                id: id
            }
        })
        dispatch({
            type: "TOGGLE_APPROVE_BANK_MODAL",
            data: {
                toggle: true
            }
        })
    }

    const toggleCancelModal = (id) => {
        dispatch({
            type: "SET_WITHDRAWAL_ID",
            data: {
                id: id
            }
        })
        dispatch({
            type: "TOGGLE_DECLINE_BANK_MODAL",
            data: {
                toggle: true
            }
        })
    }
    const toggleViewModal = (id) => {
        dispatch({
            type: "SET_WITHDRAWAL_ID",
            data: {
                id: id
            }
        })
        dispatch({
            type: "TOGGLE_DEPOSIT_DOCUMENT_MODAL",
            data: {
                toggle: true,
                proof : rowData.proof
            }
        })
    }

    const toggleViewModalW = (id) => {
        dispatch({
            type: "SET_WITHDRAWAL_ID",
            data: {
                id: id
            }
        })
        dispatch({
            type: "TOGGLE_WITHDRAW_DETAILS_MODAL",
            data: {
                toggle: true,
                details : rowData.details
            }
        })
    }

    return (
        <Table.Cell {...props} className="link-group">
            {
                rowData['status'] == 2 ? (
                    <>
                        <a style={{ marginRight: "10px" }}>
                            <Whisper
                                placement={"top"}
                                speaker={
                                    <Tooltip>Approve</Tooltip>
                                }
                            >
                                <IconButton onClick={() => toggleApproveModal(rowData['id'])} className="table-button no-icon"><i className="fas fa-check"></i></IconButton>
                            </Whisper>
                        </a>
                        <a style={{ marginRight: "10px" }}>
                            <Whisper
                                placement={"top"}
                                speaker={
                                    <Tooltip>Cancel</Tooltip>
                                }
                            >
                                <IconButton onClick={() => toggleCancelModal(rowData['id'])} className="table-button no-icon"><i className="fas fa-times"></i></IconButton>
                            </Whisper>
                        </a>
                        {/* <a style={{ marginRight: "10px" }}>
                            <Whisper
                                placement={"top"}
                                speaker={
                                    <Tooltip>View Document</Tooltip>
                                }
                            >
                                <IconButton onClick={() => toggleViewModal(rowData['id'])} className="table-button no-icon"><i class="fas fa-eye"></i></IconButton>
                            </Whisper>
                        </a> */}

                        {
                            rowData["type"] == "Deposit" ? (
                                <a style={{ marginRight: "10px" }}>
                                    <Whisper
                                        placement={"top"}
                                        speaker={
                                            <Tooltip>View Document</Tooltip>
                                        }
                                    >
                                        <IconButton onClick={() => toggleViewModal(rowData['id'])} className="table-button no-icon"><i class="fas fa-eye"></i></IconButton>
                                    </Whisper>
                                </a>
                            ) : (
                                <a style={{ marginRight: "10px" }}>
                                    <Whisper
                                        placement={"top"}
                                        speaker={
                                            <Tooltip>View Withdraw Details</Tooltip>
                                        }
                                    >
                                        <IconButton onClick={() => toggleViewModalW(rowData['id'])} className="table-button no-icon"><i class="fas fa-eye"></i></IconButton>
                                    </Whisper>
                                </a>
                            )
                        }
                    </>
                ) : (
                    <a style={{ marginRight: "10px" }}>
                        -
                    </a>
                )
            }
        </Table.Cell>
    );
};

const ApproveModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);
    const [loading, setLoading] = useState(false);

    const toggleApproveModal = () => {
        dispatch({
            type: "TOGGLE_APPROVE_BANK_MODAL",
            data: {
                toggle: false
            }
        })
    }

    const ApproveTransaction = () => {
        setLoading(true);

        axios.post(window.__APP__.api + "/payments/pendingbanktransfers/approve/" + modals.withdrawalId + "?platform=" + platforms.platform.id, {}, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if (response.data.error == null) {
                toggleApproveModal();
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                }
            }
        })
    }

    return (
        <Modal onClose={toggleApproveModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Approve Transaction #{modals.withdrawalId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to approve this transaction ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleApproveModal} appearance="subtle">
                    Close
                </Button>
                <Button loading={loading} onClick={ApproveTransaction} color="blue" appearance="primary">
                    Approve
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const CancelModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);
    const [loading, setLoading] = useState(false);

    const toggleApproveModal = () => {
        dispatch({
            type: "TOGGLE_DECLINE_BANK_MODAL",
            data: {
                toggle: false
            }
        })
    }

    const CancelWithdrawal = () => {
        setLoading(true);

        axios.post(window.__APP__.api + "/payments/pendingbanktransfers/reject/" + modals.withdrawalId + "?platform=" + platforms.platform.id, {}, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);

            if (response.data.error == null) {
                toggleApproveModal();
            } else {
                if (response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                }
            }
        })
    }

    return (
        <Modal onClose={toggleApproveModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Cancel Transaction #{modals.withdrawalId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to cancel this transaction ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleApproveModal} appearance="subtle">
                    Close
                </Button>
                <Button loading={loading} onClick={CancelWithdrawal} color="red" appearance="primary">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ViewDepositDocumentModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleApproveModal = () => {
        dispatch({
            type: "TOGGLE_DEPOSIT_DOCUMENT_MODAL",
            data: {
                toggle: false
            }
        })
    }

    return (
        <Modal onClose={toggleApproveModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>View Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <iframe
                    src={modals.bankPreviewProof}
                    alt="Document"
                    style={{ width: "100%", minHeight: "400px", maxWidth: "600px", border : "none", marginTop : "20px" }}
                />            
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleApproveModal} appearance="subtle">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ViewWithdrawDetailsModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleApproveModal = () => {
        dispatch({
            type: "TOGGLE_WITHDRAW_DETAILS_MODAL",
            data: {
                toggle: false
            }
        })
    }

    return (
        <Modal onClose={toggleApproveModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>View Withdraw Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ display : "flex", flexDirection : "column", marginTop : "10px", gap : "10px" }}>
                    <div style={{ display : "flex" }}>
                        <div style={{ flex : 1 }}>
                            <span style={{ fontWeight : "600" }}>Firstname</span>
                        </div>
                        <div style={{ flex : 1 }}>
                            <span>{modals.withdrawDetails != null ? modals.withdrawDetails.firstname : "-" }</span>
                        </div>
                    </div>

                    <div style={{ display : "flex" }}>
                        <div style={{ flex : 1 }}>
                            <span style={{ fontWeight : "600" }}>Lastname</span>
                        </div>
                        <div style={{ flex : 1 }}>
                            <span>{modals.withdrawDetails != null ? modals.withdrawDetails.lastname : "-" }</span>
                        </div>
                    </div>

                    <div style={{ display : "flex" }}>
                        <div style={{ flex : 1 }}>
                            <span style={{ fontWeight : "600" }}>Email</span>
                        </div>
                        <div style={{ flex : 1 }}>
                            <span>{modals.withdrawDetails != null ? modals.withdrawDetails.email : "-" }</span>
                        </div>
                    </div>

                    <div style={{ display : "flex" }}>
                        <div style={{ flex : 1 }}>
                            <span style={{ fontWeight : "600" }}>Account number</span>
                        </div>
                        <div style={{ flex : 1 }}>
                            <span>{modals.withdrawDetails != null ? modals.withdrawDetails.account : "-" }</span>
                        </div>
                    </div>

                    <div style={{ display : "flex" }}>
                        <div style={{ flex : 1 }}>
                            <span style={{ fontWeight : "600" }}>Bank</span>
                        </div>
                        <div style={{ flex : 1 }}>
                            <span>{modals.withdrawDetails != null ? modals.withdrawDetails.bank : "-" }</span>
                        </div>
                    </div>
                </div>         
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleApproveModal} appearance="subtle">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function BankTransferTable(props) {

    const dispatch = useDispatch();
    const history = useHistory();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);
    const filters = useSelector(state => state.filters);

    const [pages, setPages] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [activeLimit, setActiveLimit] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);

    const { type, status } = props;

    const GetHistory = () => {
        setLoading(true);
        setTableData([]);
        // setActivePage(1);

        if (filters.dateRange.length > 0)
            var dateQuery = "&dateFrom=" + filters.dateRange[0] + "&dateTo=" + filters.dateRange[1];
        else
            var dateQuery = "";

        axios.get(window.__APP__.api + "/payments/payments/listpendingbanktransfers?platform=" + platforms.platform.id + "&page=" + activePage + "&limit=" + activeLimit + "&status=" + type + "&type=" + status + dateQuery, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if (response.data.error == null) {
                setTableData(response.data.payments);
                setPages(response.data.pagination.pages);
            } else {
                if (response.data.error == "Unauthorized") {
                    dispatch({
                        type: "SET_LOGGED",
                        data: {
                            logged: false
                        }
                    })
                    localStorage.removeItem("jwt");
                    history.push("/");
                }
            }
        });
    }

    useEffect(() => {
        if (platforms.platform.id >= 0)
            GetHistory();
    }, [platforms.platform, activePage, activeLimit, filters.dateRange, modals.withdrawalCancelModal, modals.withdrawalsModal, modals.ApproveBankModal, modals.DeclineBankModal, type, status]);


    return (
        <>
            <Table
                // height={600}
                autoHeight
                data={tableData}
                // sortColumn={sortColumn}
                // sortType={sortType}
                // onSortColumn={handleSortColumn}
                loading={loading}
            >
                <Table.Column align="center">
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <Table.Cell dataKey="id" />
                </Table.Column>

                <Table.Column minWidth={200} flexGrow={1} align="center">
                    <Table.HeaderCell>Player</Table.HeaderCell>
                    <Table.Cell>{data => <Link to={"/online/player/" + data.userId}>{data.username}</Link>}</Table.Cell>
                </Table.Column>

                <Table.Column minWidth={100} flexGrow={1} align="center">
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.Cell dataKey="amount" />
                </Table.Column>

                <Table.Column minWidth={250} flexGrow={1} align="center">
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.Cell dataKey="date" />
                </Table.Column>

                <Table.Column minWidth={100} flexGrow={1} align="center">
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.Cell>{data => <>{data.status == 2 ? <Badge color="blue" content="Pending" /> : data.status == 1 ? <Badge color="green" content="Processed" /> : data.status == 3 ? <Badge color="green" content="Approved Manually" /> : data.status == 0 ? <Badge color="red" content="Canceled" /> : <></>}</>}</Table.Cell>
                </Table.Column>

                <Table.Column minWidth={250} flexGrow={1} align="center">
                    <Table.HeaderCell>Approval Date</Table.HeaderCell>
                    <Table.Cell>{data => <>{data.approvalDate}</>}</Table.Cell>
                </Table.Column>
                <Table.Column flexGrow={1} align="center">
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.Cell>{data => <>{data.type}</>}</Table.Cell>
                </Table.Column>
                <Table.Column minWidth={200} flexGrow={1} align="center">
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                    <ActionCell />
                </Table.Column>
            </Table>

            <div className="pagination-box">
                <Pagination
                    layout={['', '-', 'limit', '|', 'pager']}
                    pages={pages}
                    limit={activeLimit}
                    limitOptions={[25, 50, 100]}
                    maxButtons={5}
                    activePage={activePage}
                    // onChangePage={setActivePage}
                    onChangeLimit={setActiveLimit}
                />
            </div>

            {
                modals.ApproveBankModal && (
                    <ApproveModal show={true} />
                )
            }

            {
                modals.DeclineBankModal && (
                    <CancelModal show={true} />
                )
            }
            {
                modals.ViewDepositDocumentModal && (
                    <ViewDepositDocumentModal show={true} />
                )
            }
            {
                modals.withdrawDetailsModal && (
                    <ViewWithdrawDetailsModal show={true} />
                )
            }
        </>
    )
}