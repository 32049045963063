import {Table, IconButton} from "rsuite";
import {Panel, InputPicker} from "rsuite";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Tooltip, Toggle, Whisper, Modal, Button, Checkbox, ButtonGroup} from "rsuite";
import DateRangePicker from 'rsuite/DateRangePicker';

import {Link} from "react-router-dom";

import axios from "axios";

import ReactExport from "react-data-export";
import { CSVLink, CSVDownload } from "react-csv";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";

function Download(props) {

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


    return (
        <>
            {
                props.dataType == 0 ? (
                    <ExcelFile hideElement={true} filename="History Data" fileExtension={props.dataType == 0 ? "xlsx" : "csv"}>
                        <ExcelSheet data={props.data} name="History">
                            {
                                props.scolumns.filter(d => {
                                    return d.active == true
                                }).map(column => {
                                    return (
                                        <ExcelColumn label={column.name} value={column.key} />
                                    )
                                })
                            }
                        </ExcelSheet>
                    </ExcelFile>
                ) : (
                    <>
                        <CSVDownload data={props.csvdata} target="_blank" />;
                    </>
                )
            }
        </>
    );
}
const selectKeys = (arr, keys) => {
    return arr.map(item => 
      keys.reduce((acc, key) => {
        if (item.hasOwnProperty(key)) {
          acc[key] = item[key];
        }
        return acc;
      }, {})
    );
};
const ExportModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);
    
    const [dataPage, setDataPage] = useState(0);
    const [dataType, setDataType] = useState(0);
    const [loading, setLoading] = useState(false);

    const [downloadable, setDownloadable] = useState(false);
    const [data, setData] = useState([]);
    const [csvdata, setCsvData] = useState([]);
    const [scolumns, setSColumns] = useState([]);

    const ExportData = async () => {
        setLoading(true);

        const response = await axios.get(window.__APP__.api+"/players/history/"+props.id+"?platform="+platforms.platform.id+"&page="+props.page+"&limit="+props.limit+"&filterType="+props.filters.filterType+"&dateFrom="+props.filters.dateFrom+"&dateTo="+props.filters.dateTo+"&provider="+props.filters.provider+"&winSort="+props.filters.sortType+"&ShowAllData="+dataPage, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        setLoading(false);

        if(response.data.error == null) {
            setData(response.data.history);

            var columns = [];

            for(var k=0; k<props.columns.length; k++) {
                var column = props.columns[k];

                if(column.active == true) {
                    columns.push(column.key)
                }
            }

            setSColumns(columns);
            setCsvData(selectKeys(response.data.history, columns))

            setDownloadable(true);

            props.closeModal();
        }
    }   

    return (
        <Modal onClose={props.closeModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Export Players Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="export-data-body">
                    <div className="export-data-row">
                        <label>Export Data Page</label>
                        <InputPicker onChange={v => setDataPage(v)} value={dataPage} placeholder="Export Data Page" data={[
                            {
                                label : "Current Page Data",
                                value : 0
                            },
                            {
                                label : "All Data",
                                value : 1
                            }
                        ]}></InputPicker>
                    </div>

                    <div className="export-data-row">
                        <label>Export Data Type</label>
                        <InputPicker onChange={v => setDataType(v)} value={dataType} placeholder="Export Data Type" data={[
                            {
                                label : "XLS",
                                value : 0
                            },
                            {
                                label : "CSV",
                                value : 1
                            }
                        ]}></InputPicker>
                    </div>
                </div>
                {
                    downloadable ? (
                        <Download scolumns={props.columns} dataType={dataType} csvdata={csvdata} data={data} />
                    ) : (
                        <></>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.closeModal} appearance="subtle">
                    Close
                </Button>
                <Button loading={loading} onClick={ExportData} color="primary" appearance="primary">
                    Export
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
const ColumnsModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    var [mcolumns, setMColumns] = useState(props.dcolumns);

    const UpdateColumnStatus = (key) => {
        var m = Object.assign([], mcolumns);

        var checkColumn = m.findIndex(d => {
            return d.key == key
        })

        if(checkColumn >= 0) {
            if(m[checkColumn].active) {
                m[checkColumn].active = false
            } else {
                m[checkColumn].active = true
            }
        }

        setMColumns(m);
    }

    const Save = () => {
        props.UpdateColumns(mcolumns);
        props.closeModal()
    }

    return (
        <Modal onClose={props.closeModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Select Table Columns</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="checkbox-columnss">
                    {
                        props.dcolumns.map(column => {
                            return (
                                <Checkbox onChange={v => UpdateColumnStatus(column.key)} checked={column.active ? true : false}>{column.name}</Checkbox>
                            )
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.closeModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={Save} color="primary" appearance="primary">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ActionCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Table.Cell {...props} className="link-group">
        <Link style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Details</Tooltip>
                }
            >
                <IconButton className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
            </Whisper>
        </Link>
      </Table.Cell>
    );
};

export default function History() {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);
    const [filterType, setFilterType] = useState(-1);

    const [transactions, setTransactions] = useState([]);

    const [showExportModal, setShowExportModal] = useState(false);
    const [showColumnsModal, setShowColumnsModal] = useState(false);

    const [providers, setProviders] = useState([]);

    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [provider, setProvider] = useState("");
    const [loading, setLoading] = useState(false);

    const [sortColumn, setSortColumn] = useState("");
    const [sortType, setSortType] = useState("");

    const [dcolumns, setDColumns] = useState([
        {
            key : "id",
            name : "Id",
            active : true
        },
        {
            key : "date",
            name : "Date",
            active : true
        },
        {
            key : "game",
            name : "Game",
            active : true
        },
        {
            key : "provider",
            name : "Provider",
            active : true
        },
        {
            key : "bet",
            name : "Bet",
            active : true
        },
        {
            key : "win",
            name : "Win",
            active : true
        },
        {
            key : "balancebe",
            name : "Balance Before",
            active : true
        },
        {
            key : "balanceaf",
            name : "Balance After",
            active : true
        },
        {
            key : "balancebonus",
            name : "Bonus Balance",
            active : true
        },
        {
            key : "spbalancebonus",
            name : "Sports Bonus Balance",
            active : true
        }
    ]);

    const GetColumnStatus = (key) => {
        var d = dcolumns.findIndex(d => {
            return d.key == key
        })

        if(d >= 0) {
            if(dcolumns[d].active) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    const closeExportModal = () => {
        setShowExportModal(false);
    }
    const closeColumnsModal = () => {
        setShowColumnsModal(false);
    }

    const GetTransactions = () => {
        setLoading(true);
        axios.get(window.__APP__.api+"/players/history/"+id+"?platform="+platforms.platform.id+"&page="+page+"&limit="+limit+"&filterType="+filterType+"&dateFrom="+dateFrom+"&dateTo="+dateTo+"&provider="+provider+"&winSort="+sortType, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            setLoading(false);
            if(response.data.error == null) {
                setTransactions(response.data.history);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    const GetProviders = () => {
        axios.get(window.__APP__.api+"/platform/providers?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setProviders(response.data.providers)
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        GetProviders();
    }, [])

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetTransactions();
        }
    }, [platforms.platform, page, limit, filterType, dateFrom, dateTo, provider, sortType]);

    const setDateRange = (dates) => {
        if(dates.length == 2) {
            setDateFrom(moment(dates[0]).format("YYYY-MM-DD HH:mm:ss"));
            setDateTo(moment(dates[1]).format("YYYY-MM-DD HH:mm:ss"));
        } else {
            setDateFrom("");
            setDateTo("");
        }
    }

    const handleSortColumn = (value, type) => {
        setSortType(type)
    }

    return (
        <>
            <div className="nav-overview">
                <Panel className="filters-panel" bordered>
                    <div className="panel-head">
                            <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                            <h1>Filters</h1>
                    </div>

                    <div className="panel-body">
                        <DateRangePicker showOneCalendar onChange={setDateRange} style={{marginLeft : "20px", minWidth : "220px", flexGrow : 1, flex : 1}} placeholder="Range" />
                        <InputPicker onChange={setProvider} data={providers} style={{marginLeft : "20px", minWidth : "220px", flexGrow : 1, flex : 1}} placeholder="Provider" />
                    </div>
                </Panel>
                <Panel header={
                            <div className="table-data-panel-header">
                                <ButtonGroup>
                                    <Button onClick={() => setShowExportModal(true)} color="primary">Export Data</Button>
                                    <Button onClick={() => setShowColumnsModal(true)} color="secondary"><i className="fas fa-cog"></i></Button>
                                </ButtonGroup>
                            </div>
                }>
                    <Table
                        // height={600}
                        autoHeight
                        data={transactions}
                        loading={loading}
                        sortColumn={"win"}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        // sortColumn={sortColumn}
                        // sortType={sortType}
                        // onSortColumn={handleSortColumn}
                        // loading={loading}
                    >
                        {
                            GetColumnStatus("id") ? (
                                <Table.Column minWidth={200} align="center" fixed>
                                    <Table.HeaderCell>Id</Table.HeaderCell>
                                    <Table.Cell dataKey="id" />
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }
                        {
                            GetColumnStatus("date") ? (
                                <Table.Column minWidth={160} flexGrow={1}>
                                    <Table.HeaderCell>Date</Table.HeaderCell>
                                    <Table.Cell dataKey="date" />
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }
                        {
                            GetColumnStatus("game") ? (
                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Game</Table.HeaderCell>
                                    <Table.Cell dataKey="game" />
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }
                        {
                            GetColumnStatus("provider") ? (
                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Provider</Table.HeaderCell>
                                    <Table.Cell dataKey="provider" />
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }
                        {
                            GetColumnStatus("bet") ? (
                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Bet</Table.HeaderCell>
                                    <Table.Cell dataKey="bet" />
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }
                        {
                            GetColumnStatus("win") ? (
                                <Table.Column sortable minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Win</Table.HeaderCell>
                                    <Table.Cell dataKey="win" />
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }
                        {
                            GetColumnStatus("balancebe") ? (
                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Balance Before</Table.HeaderCell>
                                    <Table.Cell dataKey="balancebe" />
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }
                        {
                            GetColumnStatus("balanceaf") ? (
                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Balance After</Table.HeaderCell>
                                    <Table.Cell dataKey="balanceaf" />
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }
                        {
                            GetColumnStatus("balancebonus") ? (
                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Bonus Balance</Table.HeaderCell>
                                    <Table.Cell dataKey="balancebonus" />
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }
                        {
                            GetColumnStatus("spbalancebonus") ? (
                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Sports Bonus Balance</Table.HeaderCell>
                                    <Table.Cell dataKey="spbalancebonus" />
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }

                        <Table.Column minWidth={120} flexGrow={1} align="center">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <ActionCell />
                        </Table.Column>
                    </Table>

                    <div className="pagination-box">
                        <Pagination
                            layout={['', '-', 'limit', '|', 'pager']}
                            pages={pages}
                            limit={limit}
                            limitOptions={[25,50,100]}
                            maxButtons={5}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={setLimit}
                        />
                    </div>
                </Panel>  
            </div>

            {
                showExportModal ? (
                    <ExportModal id={id} columns={dcolumns} filters={{filterType, dateFrom, dateTo, provider, sortType}} limit={limit} page={page} closeModal={closeExportModal} show={showExportModal}></ExportModal>
                ) : (
                    <></>
                )
            }

            {
                showColumnsModal ? (
                    <ColumnsModal UpdateColumns={setDColumns} dcolumns={dcolumns} showColumnsModal={true} show={showColumnsModal} closeModal={closeColumnsModal} />
                ) : (
                    <></>
                )
            }
        </>
    )
}