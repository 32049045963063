import React from "react";

import { Navbar, Nav, Dropdown, IconButton, Sidenav, Button, Input } from 'rsuite';

import { FaBolt, FaRegUser } from 'react-icons/fa';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import {IoMdNotificationsOutline} from "react-icons/io";
import { FaExchangeAlt } from "react-icons/fa";
import {FiGlobe} from "react-icons/fi";

import {useDispatch, useSelector} from "react-redux";

import {Link} from "react-router-dom";

import {useState, useEffect} from "react";
import { useHistory } from "react-router";

import {useMediaQuery} from "react-responsive";

import moment from "moment-timezone";
  
import axios from "axios";
import QuickSearch from "./QuickSearch";

function NavMenu() {

    const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

    const dispatch = useDispatch();
    const history = useHistory();

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);
    const userx = useSelector(state => state.user);

    const [datetime, setDateTime] = useState("");

    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        setInterval(() => {
            if(userx.user.timezone != null) {
                setDateTime(moment().tz(userx.user.timezone).format("HH:mm:ss"))
            } else {
                setDateTime(moment().format("HH:mm:ss"))
            }
        }, 1000)
    }, [userx])

    const toggleSideBar = () => {
        if(bars.sidebar) {
            dispatch({
                type : "TOGGLE_SIDEBAR",
                data : {
                    show : false
                }
            })
        } else {
            dispatch({
                type : "TOGGLE_SIDEBAR",
                data : {
                    show : true
                }
            })
        }
    }

    const ChangePlatform = function(platform) {
        axios.post(window.__APP__.api+"/platform/change", {
            platform : platform
        }, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                var platform = response.data.platform;

                localStorage.setItem("platform", platform.platform_id);

                dispatch({
                    type : "SET_PLATFORM",
                    data : {
                      platform : {
                        id : platform.platform_id,
                        name : platform.platform_name,
                        type : platform.platform_type,
                        credits_type : platform.platform_credits_type,
                        credits : platform.platform_credits,
                        is_limited : platform.is_limited,
                        is_mc : platform.is_mc,
                        agent_enabled : platform.agent_enabled,
                        external_agent : platform.external_agent,
                        version : platform.version,
                        mbank_enabled : platform.mbank_enabled,
                        kiosk_agent : platform.kiosk_agent
                      }
                    }
                })

                history.push("/")
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    const GetCurrencies = () => {
        axios.get(window.__APP__.api+"/platform/currencies?platform="+platforms.platform.id, {
            headers : {
                Authorization : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setCurrencies(response.data.currencies);

                if(response.data.currencies.length > 0) {
                    if(localStorage.getItem("rd-default-currency") == undefined) {
                        var checkDefault = response.data.currencies.find(currency => {
                            return currency.is_default == 1
                        })
                    } else {
                        var cid = localStorage.getItem("rd-default-currency");
                        var checkDefault = response.data.currencies.find(currency => {
                            return currency.id == cid
                        })
                    }

                    if(checkDefault != undefined) {

                        if(localStorage.getItem("rd-default-currency") == undefined) {
                            localStorage.setItem("rd-default-currency", checkDefault.id);
                        }

                        dispatch({
                            type : "SET_CURRENCY",
                            data : {
                                currency : {
                                    id : checkDefault.id,
                                    name : checkDefault.currency
                                }
                            }
                        })
                    } else {
                        dispatch({
                            type : "SET_CURRENCY",
                            data : {
                                currency : {
                                    id : response.data.currencies[0].id,
                                    name : response.data.currencies[0].currency
                                }
                            }
                        })
                    }
                }
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0 && platforms.platform.is_mc == 1) {
            GetCurrencies();
        }
    }, [platforms.platform]);

    const GoToAccount = () => {
        history.push("/account/edit");
    }
    const GoToLogs = () => {
        history.push("/logs");
    }

    const Logout = () => {
        localStorage.removeItem("jwt");
        dispatch({
            type : "SET_LOGGED",
            data : {
                logged : false
            }
        })
        history.push("/");
    }

    const SetCurrency = (id, name) => {
        localStorage.setItem("rd-default-currency", id);
        dispatch({
            type : "SET_CURRENCY",
            data : {
                currency : {
                    id : id,
                    name : name
                }
            }
        })
    }

    const showDeposit = () => {
        dispatch({
            type : "SET_DEPOSIT_MODAL",
            data : {
                modal : true
            }
        })
    }

    const ShowTransferModal = () => {
        // dispatch({
        //     type : "TOGGLE_TRANSFER_MODAL",
        //     data : {
        //         toggle : true
        //     }
        // })
        history.push("/transfer");
    }

    return (
        <>
            <Navbar>
                <Nav>
                    <Nav.Item className="toggle-sidebar-icon" onClick={toggleSideBar}>{!bars.sidebar ? <AiOutlineMenuFold style={{fontSize : "20px", color : "#000 !important"}} /> : <AiOutlineMenuUnfold style={{fontSize : "20px", color : "#000 !important"}} />}</Nav.Item>
                    <Dropdown className="platforms-dropdown" title={<>{platforms.platform.id != -1 ? platforms.platform.name : "N/A"}</>}>
                        {
                            platforms.platforms.map(platform => {
                                return (
                                    <>
                                    {
                                        platform.platform_id != platforms.platform.id && (
                                            <Dropdown.Item onClick={() => ChangePlatform(platform.platform_id)}>{platform.platform_name}</Dropdown.Item>
                                        )
                                    }
                                    </>
                                )
                            })
                        }
                    </Dropdown>
                </Nav>
                <Nav pullRight>
                    <Nav.Item className="datetime">{datetime}</Nav.Item>

                    {
                        (platforms.platform.credits_type == 1 && platforms.platform.platform_deposit) && (
                            <>
                                {
                                    !isMobile && (
                                        <Nav.Item className="toggle-sidebar-icon"><Button size="xs" color="primary" onClick={showDeposit}><i className="fa fa-"></i> Deposit</Button></Nav.Item>
                                    )
                                }
                            </>
                        )
                    }
                    
                    {
                        (platforms.platform.credits_type == 1) && (
                            <>
                                {
                                    !isMobile && (
                                        <Nav.Item className="toggle-sidebar-icon">{parseFloat(platforms.platform.credits).toFixed(2)} TND</Nav.Item>
                                    )
                                }
                            </>
                        )
                    }

                    <Dropdown className="user-side-notification" icon={<IoMdNotificationsOutline style={{fontSize : "22px"}} />} placement="bottomEnd" noCaret>
                        
                    </Dropdown>
                    {
                        (platforms.platform.id >= 0 && platforms.platform.is_mc == 1) ? (
                            <Dropdown className="currency-tag" title={platforms.currency.name}>
                                {
                                    currencies.map(currency => {
                                        return (
                                            <Dropdown.Item onClick={() => SetCurrency(currency.id, currency.currency)}>{currency.currency}</Dropdown.Item>
                                        )
                                    })
                                }
                            </Dropdown>
                        ) : (
                            <></>
                        )
                    }
                    <Dropdown onClick={ShowTransferModal} className="user-side-notification" icon={<FaExchangeAlt style={{fontSize : "15px"}} />} placement="bottomEnd" noCaret></Dropdown>
                    <Dropdown className="user-side" icon={<FaRegUser style={{fontSize : "18px"}} />} placement="bottomEnd" noCaret>
                        {/* {
                            isMobile && (
                                <Dropdown.Item>{parseFloat(platforms.platform.credits).toFixed(2)} TND</Dropdown.Item>
                            )
                        } */}

                        <Dropdown.Item onClick={GoToAccount}><i style={{fontSize : "15px", marginRight : "5px"}} className="fas fa-user" /> My Account</Dropdown.Item>
                        <Dropdown.Item onClick={GoToLogs}><i style={{fontSize : "15px", marginRight : "5px"}} className="fas fa-history" /> Login Logs</Dropdown.Item>
                        <Dropdown.Item onClick={Logout}><i style={{fontSize : "15px", marginRight : "5px"}} className="fas fa-sign-out-alt" /> Logout</Dropdown.Item>
                    </Dropdown>
                    <QuickSearch />
                </Nav>
            </Navbar>
        </>
    )
}

export default NavMenu;