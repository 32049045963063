import {Table, IconButton} from "rsuite";
import {Panel, InputPicker} from "rsuite";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Tooltip, Toggle, Whisper, Modal, Button, Checkbox, ButtonGroup} from "rsuite";
import DateRangePicker from 'rsuite/DateRangePicker';

import ReactExport from "react-data-export";
import { CSVLink, CSVDownload } from "react-csv";

import {Link} from "react-router-dom";

import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {useHistory, useParams} from "react-router-dom";

import { Badge } from "rsuite";

import moment from "moment";

function Download(props) {

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


    return (
        <>
            {
                props.dataType == 0 ? (
                    <ExcelFile hideElement={true} filename="Transactions Data" fileExtension={props.dataType == 0 ? "xlsx" : "csv"}>
                        <ExcelSheet data={props.data} name="Transactions">
                            {
                                props.scolumns.filter(d => {
                                    return d.active == true
                                }).map(column => {
                                    return (
                                        <ExcelColumn label={column.name} value={column.key} />
                                    )
                                })
                            }
                        </ExcelSheet>
                    </ExcelFile>
                ) : (
                    <>
                        <CSVDownload data={props.csvdata} target="_blank" />;
                    </>
                )
            }
        </>
    );
}
const selectKeys = (arr, keys) => {
    return arr.map(item => 
      keys.reduce((acc, key) => {
        if (item.hasOwnProperty(key)) {
          acc[key] = item[key];
        }
        return acc;
      }, {})
    );
};
const ExportModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);
    
    const [dataPage, setDataPage] = useState(0);
    const [dataType, setDataType] = useState(0);
    const [loading, setLoading] = useState(false);

    const [downloadable, setDownloadable] = useState(false);
    const [data, setData] = useState([]);
    const [csvdata, setCsvData] = useState([]);
    const [scolumns, setSColumns] = useState([]);

    const ExportData = async () => {
        setLoading(true);

        const response = await axios.get(window.__APP__.api+"/players/transactions/"+props.id+"?platform="+platforms.platform.id+"&page="+props.page+"&limit="+props.limit+"&filterType="+props.filters.filterType+"&dateFrom="+props.filters.dateFrom+"&dateTo="+props.filters.dateTo+"&ShowAllData="+dataPage, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        setLoading(false);

        if(response.data.error == null) {
            setData(response.data.transactions);

            var columns = [];

            for(var k=0; k<props.columns.length; k++) {
                var column = props.columns[k];

                if(column.active == true) {
                    columns.push(column.key)
                }
            }

            setSColumns(columns);
            setCsvData(selectKeys(response.data.transactions, columns))

            setDownloadable(true);

            props.closeModal();
        }
    }   

    return (
        <Modal onClose={props.closeModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Export Players Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="export-data-body">
                    <div className="export-data-row">
                        <label>Export Data Page</label>
                        <InputPicker onChange={v => setDataPage(v)} value={dataPage} placeholder="Export Data Page" data={[
                            {
                                label : "Current Page Data",
                                value : 0
                            },
                            {
                                label : "All Data",
                                value : 1
                            }
                        ]}></InputPicker>
                    </div>

                    <div className="export-data-row">
                        <label>Export Data Type</label>
                        <InputPicker onChange={v => setDataType(v)} value={dataType} placeholder="Export Data Type" data={[
                            {
                                label : "XLS",
                                value : 0
                            },
                            {
                                label : "CSV",
                                value : 1
                            }
                        ]}></InputPicker>
                    </div>
                </div>
                {
                    downloadable ? (
                        <Download scolumns={props.columns} dataType={dataType} csvdata={csvdata} data={data} />
                    ) : (
                        <></>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.closeModal} appearance="subtle">
                    Close
                </Button>
                <Button loading={loading} onClick={ExportData} color="primary" appearance="primary">
                    Export
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
const ColumnsModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    var [mcolumns, setMColumns] = useState(props.dcolumns);

    const UpdateColumnStatus = (key) => {
        var m = Object.assign([], mcolumns);

        var checkColumn = m.findIndex(d => {
            return d.key == key
        })

        if(checkColumn >= 0) {
            if(m[checkColumn].active) {
                m[checkColumn].active = false
            } else {
                m[checkColumn].active = true
            }
        }

        setMColumns(m);
    }

    const Save = () => {
        props.UpdateColumns(mcolumns);
        props.closeModal()
    }

    return (
        <Modal onClose={props.closeModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Select Table Columns</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="checkbox-columnss">
                    {
                        props.dcolumns.map(column => {
                            return (
                                <Checkbox onChange={v => UpdateColumnStatus(column.key)} checked={column.active ? true : false}>{column.name}</Checkbox>
                            )
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.closeModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={Save} color="primary" appearance="primary">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const TransactionDetails = (props) => {
    const dispatch = useDispatch();

    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const [loading, setLoading] = useState(false);

    const toggleDetailsModal = () => {
        dispatch({
            type : "SET_O_TRANSACTION_MODAl",
            data : {
                modal : false,
                details : null
            }
        })
    }

    return (
        <Modal size={"md"} onClose={toggleDetailsModal} open={modals.onlineTransactionModal}>
            <Modal.Header>
                <Modal.Title>Transaction Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="details-table">
                <Table
                    height={350}
                    data={modals.onlineTransactionDetails}
                    className="details-table"
                >
                    <Table.Column flexGrow={1} align="center">
                        <Table.HeaderCell>Transaction Detail</Table.HeaderCell>
                        <Table.Cell dataKey="detail" />
                    </Table.Column>
                    <Table.Column flexGrow={1} align="center">
                        <Table.HeaderCell>Transaction Detail Value</Table.HeaderCell>
                        <Table.Cell dataKey="value" />
                    </Table.Column>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDetailsModal} appearance="subtle">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const ActionCell = ({ rowData, dataKey, ...props }) => {

    const dispatch = useDispatch();

    const params = useParams();

    const platforms = useSelector(state => state.platforms);

    const ShowTransactionDetails = async (id) =>{

        const response = await axios.get(window.__APP__.api+"/players/transaction/details/"+id+"?platform="+platforms.platform.id+"&player="+params.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.error == null) {

            var detailsArray = [];

            if(response.data.details.Type == 0) {
                detailsArray.push({
                    detail : "Id",
                    value : response.data.details.Id
                })
                detailsArray.push({
                    detail : "Type",
                    value : "Deposit"
                })
                detailsArray.push({
                    detail : "Status",
                    value : response.data.details.Status == 1 ? "Processed" : response.data.details.Status == 2 ? "Pending" : "Failed"
                })
                detailsArray.push({
                    detail : "Method",
                    value : response.data.details.Method
                })
                detailsArray.push({
                    detail : "Full Name",
                    value : response.data.details.Name
                })
                detailsArray.push({
                    detail : "External Order ID",
                    value : response.data.details.OrderId
                })
            }

            if(response.data.details.Type == 1) {
                detailsArray.push({
                    detail : "Id",
                    value : response.data.details.Id
                })
                detailsArray.push({
                    detail : "Type",
                    value : "Withdraw"
                })
                detailsArray.push({
                    detail : "Status",
                    value : response.data.details.Status == 1 ? "Processed" : response.data.details.Status == 2 ? "Pending" : "Failed"
                })
                detailsArray.push({
                    detail : "Method",
                    value : response.data.details.Method
                })
                detailsArray.push({
                    detail : "Full Name",
                    value : response.data.details.Name
                })
                detailsArray.push({
                    detail : "Account Number",
                    value : response.data.details.AccountNumber
                })
                detailsArray.push({
                    detail : "Bank Code",
                    value : response.data.details.BankCode
                })
                detailsArray.push({
                    detail : "External Order ID",
                    value : response.data.details.OrderId
                })
            }

            dispatch({
                type : "SET_O_TRANSACTION_MODAl",
                data : {
                    modal : true,
                    details : detailsArray
                }
            })
        }

    }

    return (
        <Table.Cell {...props} className="link-group">
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Details</Tooltip>
                }
            >
                <IconButton onClick={() => ShowTransactionDetails(rowData["id"])} className="table-button no-icon"><i className="fas fa-eye"></i></IconButton>
            </Whisper>
        </Table.Cell>
    );
};

export default function Transactions() {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const platforms = useSelector(state => state.platforms);
    const modals = useSelector(state => state.modals);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pages, setPages] = useState(0);
    const [filterType, setFilterType] = useState(-1);

    const [transactions, setTransactions] = useState([]);

    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");

    const [showExportModal, setShowExportModal] = useState(false);
    const [showColumnsModal, setShowColumnsModal] = useState(false);

    const [dcolumns, setDColumns] = useState([
        {
            key : "id",
            name : "Id",
            active : true
        },
        {
            key : "date",
            name : "Date",
            active : true
        },
        {
            key : "type",
            name : "Type",
            active : true
        },
        {
            key : "amount",
            name : "Amount",
            active : true
        },
        {
            key : "balance",
            name : "Balance",
            active : true
        },
        {
            key : "bonusbalance",
            name : "Bonus Balance",
            active : true
        },
        {
            key : "status",
            name : "Status",
            active : true
        }
    ]);

    const GetColumnStatus = (key) => {
        var d = dcolumns.findIndex(d => {
            return d.key == key
        })

        if(d >= 0) {
            if(dcolumns[d].active) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    const transactionFilterTypes = [
        {
            label : "Deposit",
            value : 0
        },
        {
            label : "Withdraw",
            value : 1
        }
    ]

    const GetTransactions = () => {
        axios.get(window.__APP__.api+"/players/transactions/"+id+"?platform="+platforms.platform.id+"&page="+page+"&limit="+limit+"&filterType="+filterType+"&dateFrom="+dateFrom+"&dateTo="+dateTo, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setTransactions(response.data.transactions);
                setPages(response.data.pagination.pages);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        if(platforms.platform.id >= 0) {
            GetTransactions();
        }
    }, [platforms.platform, page, limit, filterType, dateFrom, dateTo]);

    const setDateRange = (dates) => {
        if(dates != null && dates.length == 2) {
            setDateFrom(moment(dates[0]).format("YYYY-MM-DD HH:mm:ss"));
            setDateTo(moment(dates[1]).format("YYYY-MM-DD HH:mm:ss"));
        } else {
            setDateFrom("");
            setDateTo("");
        }
    }

    const closeExportModal = () => {
        setShowExportModal(false);
    }
    const closeColumnsModal = () => {
        setShowColumnsModal(false);
    }

    return (
        <>
            <div className="nav-overview">
                <Panel className="filters-panel" bordered>
                    <div className="panel-head">
                            <FaFilter style={{fontSize : "16px", position : "relative", top : "1.5px"}} />
                            <h1>Filters</h1>
                    </div>

                    <div className="panel-body">
                        <InputPicker data={transactionFilterTypes} style={{flex : 1}} placeholder="Transaction Type" onChange={setFilterType} />
                        <DateRangePicker showOneCalendar onChange={setDateRange} style={{marginLeft : "20px", minWidth : "220px", flex : 1}} placeholder="Range" />
                    </div>
                </Panel>
                <Panel header={
                            <div className="table-data-panel-header">
                                <ButtonGroup>
                                    <Button onClick={() => setShowExportModal(true)} color="primary">Export Data</Button>
                                    <Button onClick={() => setShowColumnsModal(true)} color="secondary"><i className="fas fa-cog"></i></Button>
                                </ButtonGroup>
                            </div>
                }>
                    <Table
                        autoHeight
                        data={transactions}
                    >
                        {
                            GetColumnStatus("id") ? (
                                <Table.Column minWidth={200} align="center">
                                    <Table.HeaderCell>Id</Table.HeaderCell>
                                    <Table.Cell dataKey="id" />
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }
                        {
                            GetColumnStatus("date") ? (
                                <Table.Column minWidth={150} flexGrow={1}>
                                    <Table.HeaderCell>Date</Table.HeaderCell>
                                    <Table.Cell dataKey="date" />
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }
                        {
                            GetColumnStatus("type") ? (
                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Type</Table.HeaderCell>
                                    <Table.Cell>{ data => data.type}</Table.Cell>
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }
                        {
                            GetColumnStatus("amount") ? (
                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Amount</Table.HeaderCell>
                                    <Table.Cell dataKey="amount" />
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }
                        {
                            GetColumnStatus("balance") ? (
                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Balance</Table.HeaderCell>
                                    <Table.Cell>{ data => data.balance != null ? data.balance.toFixed(2) : "-"}</Table.Cell>
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }
                        {
                            GetColumnStatus("bonusbalance") ? (
                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Bonus Balance</Table.HeaderCell>
                                    <Table.Cell>{ data => data.bonus != null ? data.bonus.toFixed(2) : "-"}</Table.Cell>
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }
                        {
                            GetColumnStatus("status") ? (
                                <Table.Column minWidth={120} flexGrow={1}>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                    <Table.Cell>{ data => data.status == 1 ? <Badge color="green" content="Processed" /> : data.status == 3 ? <Badge color="green" content="Approved Manually" /> : data.status == 2 ? <Badge color="yellow" content="Pending" />: <Badge color="red" content="Canceled" />}</Table.Cell>
                                </Table.Column>
                            ) : (
                                <></>
                            )
                        }

                        <Table.Column minWidth={120} flexGrow={1} align="center">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <ActionCell />
                        </Table.Column>
                    </Table>

                    <div className="pagination-box">
                        <Pagination
                            layout={['', '-', 'limit', '|', 'pager']}
                            pages={pages}
                            limit={limit}
                            limitOptions={[25,50,100]}
                            maxButtons={5}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={setLimit}
                        />
                    </div>
                </Panel>  
            </div>

            {
                modals.onlineTransactionModal && (
                    <TransactionDetails />
                )
            }

            {
                showExportModal ? (
                    <ExportModal id={id} columns={dcolumns} filters={{filterType, dateFrom, dateTo}} limit={limit} page={page} closeModal={closeExportModal} show={showExportModal}></ExportModal>
                ) : (
                    <></>
                )
            }

            {
                showColumnsModal ? (
                    <ColumnsModal UpdateColumns={setDColumns} dcolumns={dcolumns} showColumnsModal={true} show={showColumnsModal} closeModal={closeColumnsModal} />
                ) : (
                    <></>
                )
            }
        </>
    )
}