import {Table, IconButton, Input} from "rsuite";
import {Panel, InputPicker} from "rsuite";
import moment from "moment";

import Pagination from 'rsuite/Pagination';
import {FaFilter} from "react-icons/fa";

import {Link} from "react-router-dom";
import {Tooltip, Form, Toggle, Whisper, Modal, Button, Message} from "rsuite";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Uploader from 'rsuite/Uploader';
import DatePicker from 'rsuite/DatePicker';

import axios from "axios";

const ActionCell = ({ rowData, dataKey, ...props }) => {
    const dispatch = useDispatch();

    const toggleDocumentsModal = (id) => {
        dispatch({
            type : "SET_DOCUMENT_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_DOCUMENTS_MODAL",
            data : {
                toggle : true
            }
        })
    }
    const toggleDocumentsUpdateModal = (id) => {
        dispatch({
            type : "SET_DOCUMENT_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_DOCUMENTS_UPDATE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    const toggleDocumentsDeleteModal = (id) => {
        dispatch({
            type : "SET_DOCUMENT_ID",
            data : {
                id : id
            }
        })
        dispatch({
            type : "TOGGLE_DOCUMENTS_DELETE_MODAL",
            data : {
                toggle : true
            }
        })
    }

    return (
      <Table.Cell {...props} className="link-group">
        <a onClick={() => toggleDocumentsModal(rowData['id'])} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Preview</Tooltip>
                }
            >
                <IconButton className="table-button view-file-icon"></IconButton>
            </Whisper>
        </a>
        <a onClick={() => toggleDocumentsUpdateModal(rowData['id'])} style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Edit</Tooltip>
                }
            >
                <IconButton className="table-button edit-file-icon"></IconButton>
            </Whisper>
        </a>
        <a onClick={() => toggleDocumentsDeleteModal(rowData['id'])}style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>Delete</Tooltip>
                }
            >
                <IconButton className="table-button delete-file-icon"></IconButton>
            </Whisper>
        </a>
      </Table.Cell>
    );
};

const SubActions = ({ rowData, dataKey, ...props }) => {
    return (
      <Table.Cell {...props} className="link-group">
        <Link style={{marginRight : "10px"}}>
            <Whisper
                placement={"top"}
                speaker={
                    <Tooltip>View File</Tooltip>
                }
            >
                <IconButton className="table-button view-file-icon"></IconButton>
            </Whisper>
        </Link>
      </Table.Cell>
    );
};

const PreviewModal = function(props) {
    const [files, setFiles] = useState([]);

    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_DOCUMENTS_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const getDocumentFiles = () => {
        axios.get(window.__APP__.api+"/players/document/"+modals.documentId+"?platform="+platforms.platform.id, {
            headers : {
                Authorization : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                setFiles(response.data.files);
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                }
            }
        });
    }

    useEffect(() => {
        getDocumentFiles();
    }, []);

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Document #{modals.documentId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table
                        height={300}
                        // autoHeight
                        data={files}
                        // sortColumn={sortColumn}
                        // sortType={sortType}
                        // onSortColumn={handleSortColumn}
                        // loading={loading}
                    >
                        <Table.Column width={30} align="center" fixed>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                        </Table.Column>

                        <Table.Column width={100} align="center">
                            <Table.HeaderCell>Document Type</Table.HeaderCell>
                            <Table.Cell dataKey="type" />
                        </Table.Column>

                        <Table.Column width={150} align="center">
                            <Table.HeaderCell>Expiry Date</Table.HeaderCell>
                            <Table.Cell dataKey="expiry" />
                        </Table.Column>

                        <Table.Column width={150} align="center">
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.Cell dataKey="status" />
                        </Table.Column>

                        <Table.Column width={100} align="center">
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                            <SubActions />
                        </Table.Column>
                    </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const UpdateModal = function(props) {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [updateForm, setUpdateForm] = useState({
        expiry : "",
        file : ""
    });
    const [updateError, setUpdateError] = useState({
        file : false
    })

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_DOCUMENTS_UPDATE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const toggleFileButton = (details) => {
        if(details.length == 0) {
            setUploadDisabled(false);
        }
    }

    const checkUploadFile = (details, type) => {
        if(details.error == null) {
          var path = "/documents/shops/"+details.file.name;
          setUpdateForm({...updateForm, file : path});
          setUploadDisabled(true);
        } else {
          setUploadDisabled(false);
        }
    }

    const UpdateDocument = () => {
        if(updateForm.file == "") {
            setUpdateError({...updateError, file: true});
        } else {
            setUpdateError({...updateError, file: false});

            axios.post(window.__APP__.api+"/shops/document/update/"+modals.documentId+"?platform="+platforms.platform.id, updateForm, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            }).then(response => {
                if(response.data.error == null) {
                    toggleDocumentsModal();
                } else {
                    if(response.data.error == "Unauthorized") {
                        localStorage.removeItem("jwt");
                        dispatch({
                            type : "SET_LOGGED",
                            data : {
                                logged : false
                            }
                        })
                    } 
                }
            })
        }
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Update Document #{modals.documentId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form style={{marginLeft : "10px"}}>
                    <Form.Group>
                        <Form.ControlLabel>Expiry Date</Form.ControlLabel>
                        <DatePicker onChange={value => setUpdateForm({...updateForm, expiry : value})} />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Document</Form.ControlLabel>
                        <Uploader onChange={(details) => toggleFileButton(details, "passport")} disabled={uploadDisabled} onSuccess={(response) => checkUploadFile(response, "passport")} action={window.__APP__.api+"/documents/upload?platform="+platforms.platform.id} data={{type : "operator"}} headers={{Authorization : "Bearer "+localStorage.getItem("jwt")}} />
                        <small style={{display : "block", height : "10px", color : updateError.file ? "red" : ""}}>File types allowed : png,jpg,docx,pdf</small>
                    </Form.Group>
                    <Form.Group>
                        <Button onClick={UpdateDocument}>Update Document</Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const DeleteModal = (props) => {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [updateForm, setUpdateForm] = useState({
        expiry : "",
        file : ""
    });
    const [updateError, setUpdateError] = useState({
        file : false
    })

    const toggleDocumentsModal = () => {
        dispatch({
            type : "TOGGLE_DOCUMENTS_DELETE_MODAL",
            data : {
                toggle : false
            }
        })
    }

    const toggleFileButton = (details) => {
        if(details.length == 0) {
            setUploadDisabled(false);
        }
    }

    const checkUploadFile = (details, type) => {
        if(details.error == null) {
          var path = "/documents/shops/"+details.file.name;
          setUpdateForm({...updateForm, file : path});
          setUploadDisabled(true);
        } else {
          setUploadDisabled(false);
        }
    }

    const DeleteDocument = () => {
        axios.post(window.__APP__.api+"/shops/document/delete/"+modals.documentId+"?platform="+platforms.platform.id, {}, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        }).then(response => {
            if(response.data.error == null) {
                toggleDocumentsModal();
            } else {
                if(response.data.error == "Unauthorized") {
                    localStorage.removeItem("jwt");
                    dispatch({
                        type : "SET_LOGGED",
                        data : {
                            logged : false
                        }
                    })
                } 
            }
        })
    }

    return (
        <Modal onClose={toggleDocumentsModal} open={props.show}>
            <Modal.Header>
                <Modal.Title>Delete Document #{modals.documentId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Confirmation</h4>
                <br />
                <p>Are your sure to delete this document ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={toggleDocumentsModal} appearance="subtle">
                    Close
                </Button>
                <Button onClick={DeleteDocument} color="red" appearance="primary">
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function Info() {
    const dispatch = useDispatch();
    const modals = useSelector(state => state.modals);
    const platforms = useSelector(state => state.platforms);
    const userx = useSelector(state => state.user);

    const {id} = useParams();

    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [filterType, setFilterType] = useState(-1);

    const [info, setInfo] = useState({});
    const [infoEdit, setInfoEdit] = useState(false);
    const [infoloading, setInfoloading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [message, setMessage] = useState("");

    const GetInfo = async () => {
        const response = await axios.get(window.__APP__.api + "/players/player/fullinfo/"+id+"?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.error == null) {
            setInfo(response.data.Result);
        }
    }

    useEffect(() => {
        if(platforms.platform.id >= 0)
            GetInfo();
    }, [platforms.platform]);

    const Save = async () => {
        setMessage("");

        setInfoloading(true);
        const response = await axios.post(window.__APP__.api + "/players/player/fullinfo/edit/"+id+"?platform="+platforms.platform.id, info.Profile, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })
        setInfoloading(false);

        if(response.data.error == null) {
            setInfoEdit(false);
            GetInfo();
        } else {
            setMessage(response.data.error);
        }
    }

    const GetCountriesList = async () => {
        const response = await axios.get(window.__APP__.api+"/settings/countries/items/list?platform="+platforms.platform.id, {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.error == null) {
            setCountries(response.data.countries);
        }
    }

    useEffect(() => {
        GetCountriesList();
    }, [])

    return (
        <>
            <div className="nav-overview player-profile-info">
                {
                    info.Profile != undefined ? (
                        <div className="player-info-box">
                            <div className="player-info-box-heading">
                                <h6>Personal Info</h6>
                                {
                                    (userx.user.permissions == null || (userx.user.permissions != null && userx.user.permissions.indexOf("player_info_tab_edit"))) >= 0 ? (
                                        <>
                                            {
                                                infoEdit ? (
                                                    <i onClick={() => setInfoEdit(false)} className="fas fa-times"></i>
                                                ) : (
                                                    <i onClick={() => setInfoEdit(true)} className="fas fa-edit"></i>
                                                )
                                            }
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                            {
                              message != "" && (
                                <div style={{marginTop : "10px"}}>
                                    <Message size="md" showIcon type={"error"}>
                                    {message}
                                    </Message>
                                </div>
                              )
                            }

                            <div className="player-info-box-body">
                                <div className="player-info-box-item">
                                    <span>Firstname</span>
                                    {
                                        infoEdit ? (
                                            <Input onChange={v => setInfo({...info, Profile : {...info.Profile, firstname : v}})} size="sm" placeholder="Firstname" value={(info.Profile.firstname != "") ? info.Profile.firstname : ""} />
                                        ) : (
                                            <span>{(info.Profile.firstname != "") ? info.Profile.firstname : "-"}</span>
                                        )
                                    }
                                </div>
                                <div className="player-info-box-item">
                                    <span>Lastname</span>
                                    {
                                        infoEdit ? (
                                            <Input onChange={v => setInfo({...info, Profile : {...info.Profile, lastname : v}})} size="sm" placeholder="Lastname" value={(info.Profile.lastname != "") ? info.Profile.lastname : ""} />
                                        ) : (
                                            <span>{info.Profile.lastname != "" ? info.Profile.lastname : "-"}</span>
                                        )
                                    }
                                </div>
                                <div className="player-info-box-item">
                                    <span>Birthday</span>
                                    {
                                        infoEdit ? (
                                            <DatePicker onChange={v => setInfo({...info, Profile : {...info.Profile, birthday : v}})} size="sm" placeholder="Birthday" value={(info.Profile.birthday != "") ? moment(info.Profile.birthday).toDate() : ""} />
                                        ) : (
                                            <span>{info.Profile.birthday != "" ? moment(info.Profile.birthday).format("DD/MM/YYYY") : "-"}</span>
                                        )
                                    }
                                </div>
                                <div className="player-info-box-item">
                                    <span>Document Number</span>
                                    {
                                        infoEdit ? (
                                            <Input onChange={v => setInfo({...info, Profile : {...info.Profile, idn : v}})} size="sm" placeholder="Document Number" value={(info.Profile.idn != "") ? info.Profile.idn : ""} />
                                        ) : (
                                            <span>{info.Profile.idn != "" ? info.Profile.idn : "-"}</span>
                                        )
                                    }
                                </div>
                                <div className="player-info-box-item">
                                    <span>Country</span>
                                    {
                                        infoEdit ? (
                                            <InputPicker data={countries} onChange={v => setInfo({...info, Profile : {...info.Profile, country : v}})} size="sm" placeholder="Country" value={(info.Profile.country != "") ? info.Profile.country : ""} />
                                        ) : (
                                            <InputPicker data={countries} disabled size="sm" placeholder="Country" value={(info.Profile.country != "") ? info.Profile.country : ""} />
                                        )
                                    }
                                </div>
                                <div className="player-info-box-item">
                                    <span>City</span>
                                    {
                                        infoEdit ? ( 
                                            <Input onChange={v => setInfo({...info, Profile : {...info.Profile, city : v}})} size="sm" placeholder="City" value={(info.Profile.city != "") ? info.Profile.city : ""} />
                                        ) : (
                                            <span>{info.Profile.city != "" ? info.Profile.city : "-"}</span>
                                        )
                                    }
                                </div>
                                <div className="player-info-box-item">
                                    <span>Email</span>
                                    {
                                        infoEdit ? ( 
                                            <Input onChange={v => setInfo({...info, Profile : {...info.Profile, email : v}})} size="sm" placeholder="Email" value={(info.Profile.email != "") ? info.Profile.email : ""} />
                                        ) : (
                                            <span>{info.Profile.email != "" ? info.Profile.email : "-"}</span>
                                        )
                                    }
                                </div>
                                <div className="player-info-box-item">
                                    <span>Phone Number</span>
                                    {
                                        infoEdit ? ( 
                                            <Input onChange={v => setInfo({...info, Profile : {...info.Profile, phone : v}})} size="sm" placeholder="Phone Number" value={(info.Profile.phone != "") ? info.Profile.phone : ""} />
                                        ) : (
                                            <span>{info.Profile.phone != "" ? info.Profile.phone : "-"}</span>
                                        )
                                    }
                                </div>
                                <div className="player-info-box-item">
                                    <span>Address</span>
                                    {
                                        infoEdit ? ( 
                                            <Input onChange={v => setInfo({...info, Profile : {...info.Profile, address : v}})} size="sm" placeholder="Address" value={(info.Profile.address != "") ? info.Profile.address : ""} />
                                        ) : (
                                            <span>{info.Profile.address != "" ? info.Profile.address : "-"}</span>
                                        )
                                    }
                                </div>
                            </div>
                            {
                                infoEdit ? (
                                    <div className="player-info-footer-action">
                                        <Button onClick={Save} loading={infoloading} color="primary">Save</Button>
                                    </div>
                                ) : (
                                    <></>
                                )
                            }
                        </div>
                    ) : (
                        <></>
                    )
                }
            </div>  
        </>
    )
}