const initialState = {
    documentsModal : false,
    documentsUpdateModal : false,
    documentsDeleteModal : false,
    addDocumentModal : false,
    partnerDeleteModal : false,
    partnerToggleModal : false,
    shopDeleteModal : false,
    shopToggleModal : false,
    playerDeleteModal : false,
    playerToggleModal : false,
    bannerDeleteModal : false,
    languageDeleteModal : false,
    languageContentModal : false,
    pagesDeleteModal : false,
    promotionsDeleteModal : false,
    transferModal : false,
    withdrawalsModal : false,
    withdrawalCancelModal : false,
    ViewDepositDocumentModal:false,
    ApproveBankModal:false,
    DeclineBankModal:false,
    playerMessageModal : false,
    playerNoteModal : false,
    shopMessageModal : false,
    shopNoteModal : false,
    partnerMessageModal : false,
    partnerNoteModal : false,
    bannerOrderModal : false,
    depositModal : false,
    onlineTransactionModal : false,
    onlineTransactionDetails : null,
    shopId : null,
    pageId : null,
    promotionId : null,
    partnerId : null,
    playerId : null,
    bannerId : null,
    languageId : null,
    partnerStatus : null,
    shopStatus : null,
    playerStatus : null,
    documentId : null,
    withdrawalId : null,
    languageContentid : null,
    languageContentValue : null,
    transferUser : null,
    transferType : null,
    partnershipId : null,
    partnershipModal : false,
    percentageModal : false,
    percentageModalId : null,
    percentage : 0,
    financialDebtsModal : false,
    financialDebtsId : null,
    financialDebtsPartner : null,
    financialRecordModal : false,
    financialRecordId : null,
    partnerPaidModal : false,
    financialPartnerId : null,
    partnerNCheckedModal : false,
    balanceModal : false,
    sportsModal : false,
    sportsId : null,
    cashierTransactionsModal : false,
    cashierId : null,
    cashierTransactionModal : false,
    cashierSettings : false,
    financialTransactionModal : false,
    createCashierModal : false,
    shopsBookModal : false,
    shopsBookId : null,
    shopBookStatus : 0,
    verificationModal : false,
    kycVerificationModal : false,
    verificationId : null,
    providersPriorityModal : false,
    providersPriorityId : null,
    gamesPriorityModal : false,
    gamesPriorityId : false,
    gamesRandomId : false,
    bankPreviewProof : "",
    withdrawDetails : null,
    withdrawDetailsModal : false
}

export default function modalsReducer(state=initialState, action) {
    switch(action.type) {
        case "TOGGLE_DOCUMENTS_MODAL":
            return {...state, documentsModal: action.data.toggle};
            break;
        case "SET_PERCENTAGE":
            return {...state, percentage : action.data.percentage};
        break;
        case "TOGGLE_PERCENTAGE_MODAL":
            return {...state, percentageModal : action.data.toggle};
        break;
        case "TOGGLE_DOCUMENTS_UPDATE_MODAL":
            return {...state, documentsUpdateModal : action.data.toggle};
        break;
        case "SET_PARTNERSHIP_ID":
            return {...state, partnershipId : action.data.id};
        break;
        case "TOGGLE_PARTNERSHIP_MODAL":
            return {...state, partnershipModal : action.data.toggle };
        break;
        case "TOGGLE_DOCUMENTS_DELETE_MODAL":
            return {...state, documentsDeleteModal : action.data.toggle};
        break;
        case "TOGGLE_PARTNER_DELETE_MODAL":
            return {...state, partnerDeleteModal : action.data.toggle};
            break;
        case "TOGGLE_PARTNER_TOGGLE_MODAL":
            return {...state, partnerToggleModal : action.data.toggle};
            break;
        case "TOGGLE_SHOP_DELETE_MODAL":
            return {...state, shopDeleteModal : action.data.toggle};
        break;
        case "TOGGLE_SHOP_TOGGLE_MODAL":
            return {...state, shopToggleModal : action.data.toggle};
        break; 
        case "TOGGLE_PLAYER_DELETE_MODAL":
            return {...state, playerDeleteModal : action.data.toggle};
        break;
        case "TOGGLE_PLAYER_TOGGLE_MODAL":
            return {...state, playerToggleModal : action.data.toggle};
        break; 
        case "TOGGLE_BANNER_DELETE_MODAL":
            return {...state, bannerDeleteModal : action.data.toggle};
        break;
        case "TOGGLE_BANNER_ORDER_MODAL":
            return {...state, bannerOrderModal : action.data.toggle};
        break;
        case "TOGGLE_LANGUAGE_DELETE_MODAL":
            return {...state, languageDeleteModal : action.data.toggle};
        break;
        case "TOGGLE_LANGUAGE_CONTENT_MODAL":
            return {...state, languageContentModal : action.data.toggle};
        break;
        case "TOGGLE_WITHDRAWALS_MODAL":
            return {...state, withdrawalsModal : action.data.toggle};
        break;
        case "TOGGLE_CANCEL_WITHDRAWALS_MODAL":
            return {...state, withdrawalCancelModal : action.data.toggle};
        break;
        case "TOGGLE_DEPOSIT_DOCUMENT_MODAL":
            return {...state, ViewDepositDocumentModal : action.data.toggle, bankPreviewProof : action.data.proof};
        break;
        case "TOGGLE_WITHDRAW_DETAILS_MODAL":
            return {...state, withdrawDetailsModal : action.data.toggle, withdrawDetails : action.data.details};
        break;
        case "TOGGLE_APPROVE_BANK_MODAL":
            return {...state, ApproveBankModal : action.data.toggle};
        break;
        case "TOGGLE_DECLINE_BANK_MODAL":
            return {...state, DeclineBankModal : action.data.toggle};
        break;
        case "TOGGLE_PAGE_DELETE_MODAL":
            return {...state, pagesDeleteModal : action.data.toggle};
        break;
        case "TOGGLE_PROMOTION_DELETE_MODAL":
            return {...state, promotionsDeleteModal : action.data.toggle};
        break;
        case "TOGGLE_TRANSFER_MODAL":
            return {...state, transferModal : action.data.toggle};
        break;
        case "TOGGLE_PLAYER_MESSAGE_MODAL":
            return {...state, playerMessageModal : action.data.toggle};
        break;
        case "TOGGLE_PLAYER_NOTE_MODAL":
            return {...state, playerNoteModal : action.data.toggle};
        break;
        case "TOGGLE_SHOP_MESSAGE_MODAL":
            return {...state, shopMessageModal : action.data.toggle};
        break;
        case "TOGGLE_SHOP_NOTE_MODAL":
            return {...state, shopNoteModal : action.data.toggle};
        break;    
        case "TOGGLE_SHOP_DOCUMENT_MODAL":
            return {...state, addDocumentModal : action.data.toggle };
        break;
        case "TOGGLE_PARTNER_MESSAGE_MODAL":
            return {...state, partnerMessageModal : action.data.toggle};
        break;
        case "TOGGLE_PARTNER_NOTE_MODAL":
            return {...state, partnerNoteModal : action.data.toggle};
        break;      
        case "TOGGLE_PARTNER_PAID_MODAL":
            return {...state, partnerPaidModal : action.data.toggle};
        break;
        case "SET_DOCUMENT_ID":
            return {...state, documentId : action.data.id};
        break;
        case "SET_PARTNER_ID":
            return {...state, partnerId : action.data.id};
        break;
        case "SET_SHOP_ID":
            return {...state, shopId : action.data.id};
        break;
        case "SET_PLAYER_ID":
            return {...state, playerId : action.data.id};
        break;
        case "SET_BANNER_ID":
            return {...state, bannerId : action.data.id};
        break;
        case "SET_LANGUAGE_ID":
            return {...state, languageId : action.data.id};
        break;
        case "SET_PAGE_ID":
            return {...state, pageId : action.data.id};
        break;
        case "SET_PROMOTION_ID":
            return {...state, promotionId : action.data.id};
        break;
        case "SET_WITHDRAWAL_ID":
            return {...state, withdrawalId : action.data.id };
        break;
        case "SET_LANGUAGE_CONTENT_ID":
            return {...state, languageContentid : action.data.id};
        break;
        case "SET_LANGUAGE_CONTENT_VALUE":
            return {...state, languageContentValue : action.data.value};
        break;
        case "SET_PARTNER_STATUS":
            return {...state, partnerStatus : action.data.status};
        break;
        case "SET_SHOP_STATUS":
            return {...state, shopStatus : action.data.status};
        break;
        case "SET_PLAYER_STATUS":
            return {...state, playerStatus : action.data.status};
        break;
        case "SET_TRANSFER_USER":
            return {...state, transferUser : action.data.id};
        break;
        case "SET_TRANSFER_TYPE":
            return {...state, transferType : action.data.type};
        break;
        case "SET_FINANCIAL_DEBTS_MODAL":
            return {...state, financialDebtsModal : action.data.show};
        break;
        case "SET_FINANCIAL_DEBTS_ID":
            return {...state, financialDebtsId : action.data.id};
        break;
        case "SET_FINANCIAL_DEBTS_PARTNER":
            return {...state, financialDebtsPartner : action.data.id};
        break;
        case "SET_FINANCIAL_RECORD_MODAL":
            return {...state, financialRecordModal : action.data.show};
        break;
        case "SET_FINANCIAL_RECORD_ID":
            return {...state, financialRecordId : action.data.id};
        break;
        case "SET_FINANCIAL_PARTNER_ID":
            return {...state, financialPartnerId : action.data.id};
        break;
        case "SET_FINANCIAL_PARTNER_NPAID_MODAL":
            return {...state, partnerNCheckedModal : action.data.show}
        break;
        case "SET_SPORTS_MODAL":
            return {...state, sportsModal : action.data.show}
        break;
        case "SET_SPORTS_ID":
            return {...state, sportsId : action.data.id}
        break;
        case "SHOW_BALANCE_MODAL":
            return {...state, balanceModal : action.data.modal}
        break;
        case "SET_CASHIER_TRANSACTIONS_MODAL":
            return {...state, cashierTransactionsModal : action.data.modal, cashierId : action.data.id}
        break;
        case "SET_CASHIER_TRANSACTION_MODAL":
            return {...state, cashierTransactionModal : action.data.modal, cashierId : action.data.id}
        break;
        case "SET_CASHIER_SETTINGS_MODAL":
            return {...state, cashierSettings : action.data.modal, partnerId : action.data.id}
        break;
        case "SET_FINANCIAL_TRANSACTION_MODAL":
            return {...state, financialTransactionModal : action.data.show}
        break;
        case "SET_CREATE_CASHIER_MODAL":
            return {...state, createCashierModal : action.data.modal}
        break;
        case "SET_SHOPS_BOOK_MODAL":
            return {...state, shopsBookModal : action.data.modal, shopsBookId : action.data.id, shopBookStatus : action.data.status}
        break;
        case "SET_DEPOSIT_MODAL":
            return {...state, depositModal : action.data.modal}
        break;
        case "SET_O_TRANSACTION_MODAl":
            return {...state, onlineTransactionModal : action.data.modal, onlineTransactionDetails : action.data.details}
        break;
        case "SET_VERIFICATION_MODAL":
            return {...state, verificationModal : action.data.modal, verificationId : action.data.id }
        break;
        case "SET_KYC_VERIFICATION_MODAL":
            return {...state, kycVerificationModal : action.data.modal, verificationId : action.data.id }
        break;
        case "SET_PROVIDERS_PRIORITY_MODAL":
            return {...state, providersPriorityModal : action.data.modal, providersPriorityId : action.data.id }
        break;
        case "SET_GAMES_PRIORITY_MODAL":
            return {...state, gamesPriorityModal : action.data.modal, gamesPriorityId : action.data.id }
        break;
        case "SET_GAMES_RANDOM_ID":
            return {...state, gamesRandomId : action.data.random}
        break;  
        default:
            return state;
        break;
    }
}