
import React, { useState, useEffect } from "react";
import Navbar from "../Navigation/Navbar";
import Sidebar from "../Navigation/Sidebar";

import { useDispatch, useSelector } from "react-redux";

import { Breadcrumb, FlexboxGrid } from "rsuite";
import { Button, IconButton, ButtonGroup, ButtonToolbar } from 'rsuite';

import { Bar } from 'react-chartjs-2';

import Overview from "./Boxes/Overview";


import UsersPartitions from "./Boxes/UsersPartitions";
import BetsCategories from "./Boxes/BetsCategories";
import GGRRegions from "./Boxes/GGRRegions";
import TopWins from "./Boxes/TopWins";
import TransactionDetails from "./Boxes/TransactionDetails";
import TransactionDetailsOnline from "./Boxes/TransactionDetailsOnline";
import PendingWithdrawals from "./Boxes/PendingWithdrawals";
import AgentPartitions from "./Boxes/AgentPartitions";
import TopBalances from "./Boxes/TopBalances";
import PendingBankTransfer from "./Boxes/PendingBankTransfer";

export default function Dashboard() {

    const bars = useSelector(state => state.bars);
    const platforms = useSelector(state => state.platforms);
    const user = useSelector(state => state.user);

    const [timeframe, setTimeframe] = useState(4);

    return (
        <>
            <div className="dashboard-page">
                <div className={"dashboard-bars" + (bars.sidebar ? "-collapsed" : "")}>
                    <Sidebar active={"overview"}></Sidebar>
                </div>

                <div className="dashboard-content">
                    <Navbar></Navbar>

                    <div className="main-content">
                        <Breadcrumb>
                            <Breadcrumb.Item active>Overview</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className="page-head">
                            <h1>Dashboard</h1>
                            <ButtonGroup size="sm">
                                <Button onClick={() => setTimeframe(1)} active={timeframe === 1}>Today</Button>
                                <Button onClick={() => setTimeframe(2)} active={timeframe === 2}>Yesterday</Button>
                                <Button onClick={() => setTimeframe(3)} active={timeframe === 3}>7 Days</Button>
                                <Button onClick={() => setTimeframe(4)} active={timeframe === 4}>Current Month</Button>
                                <Button onClick={() => setTimeframe(5)} active={timeframe === 5}>Last Month</Button>
                            </ButtonGroup>
                        </div>

                        {
                            platforms.platform != undefined && (
                                <>

                                    {
                                        (user.user.permissions == null || (user.user.permissions != null && user.user.permissions.indexOf("dashboard_overview_boxes"))) >= 0 ? (
                                            <Overview timeframe={timeframe} />
                                        ) : (
                                            <></>
                                        )
                                    }

                                    {
                                        user.user.type != 5 && (
                                            <>
                                                <div className="box-charts">
                                                    <UsersPartitions />

                                                    {
                                                        (user.user.permissions == null || (user.user.permissions != null && user.user.permissions.indexOf("dashboard_bets_categories"))) >= 0 ? (
                                                            <BetsCategories timeframe={timeframe} />
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        (user.user.permissions == null || (user.user.permissions != null && user.user.permissions.indexOf("dashboard_top_wins"))) >= 0 ? (
                                                            <GGRRegions timeframe={timeframe} />
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        platforms.platform.agent_enabled == 1 ? (
                                                            (user.user.permissions == null || (user.user.permissions != null && user.user.permissions.indexOf("dashboard_agent_partitions"))) >= 0 ? (
                                                                <AgentPartitions />
                                                            ) : (
                                                                <></>
                                                            )
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        (user.user.permissions == null || (user.user.permissions != null && user.user.permissions.indexOf("dashboard_top_balances"))) >= 0 ? (
                                                            <TopBalances timeframe={timeframe} />
                                                        ) : (
                                                            <></>
                                                        )
                                                    }

                                                </div>
                                                <div className="box-charts">
                                                    {
                                                        (platforms.platform.type != 3) && (
                                                            <TopWins />
                                                        )
                                                    }

                                                    {
                                                        platforms.platform.mbank_enabled == 1 ? (
                                                            (user.user.permissions == null || (user.user.permissions != null && user.user.permissions.indexOf("pending_bank_transfer"))) >= 0 ? (
                                                                <PendingBankTransfer />
                                                            ) : (
                                                                <></>
                                                            )
                                                        ) : (
                                                            <></>
                                                        )
                                                    }

                                                    {
                                                        platforms.platform.type != 3 ? (
                                                            <TransactionDetails />
                                                        ) : (
                                                            (user.user.permissions == null || (user.user.permissions != null && user.user.permissions.indexOf("withdrawals_list"))) >= 0 ? (
                                                                <PendingWithdrawals />
                                                            ) : (
                                                                <></>
                                                            )
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )
                                    }

                                </>
                            )
                        }

                    </div>
                </div>
            </div>
        </>
    )
}